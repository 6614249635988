import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import ItemsSection from '../../components/ItemsSection/ItemsSection';
import ServiceCards from '../../components/ServiceCards/ServiceCards';
import QuoteVideo from '../../components/QuoteVideo/QuoteVideo';
import ProductCards from '../../components/ProductCards/ProductCards';
import FinancialProductCards from '../../components/FinancialProducts/FinancialProductCards';
import HomepageCTA from '../../components/HomepageCTA/HomepageCTA';

import cornerContainer from '../../assets/icons/homepage/canto.svg';
import lineContainer from '../../assets/icons/homepage/linha.svg';
import backgroundFinancialProducts from '../../assets/images/homepage/financial_products/bg_financial_products.png';
import Opportunities from '../../components/Opportunities/Opportunities';
import Partners from '../../components/Parthers/Parthers';
import Clients from '../../components/Clients/Clients';
import Team from '../../components/Team/Team';

class Homepage extends Component {

    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="homepage" />
                <HomeBanner {...this.props} />
                <ItemsSection {...this.props} content="services" cornerContainer={cornerContainer} >
                    <ServiceCards content="services" iconsFolder="services" />
                </ItemsSection>
                <QuoteVideo {...this.props} />
                <ItemsSection split {...this.props} content="products" cornerContainer={cornerContainer} >
                    <ProductCards content="products" iconsFolder="products" />
                </ItemsSection>
                <ItemsSection {...this.props} noPadBottomMobile background={backgroundFinancialProducts} content="financial_products" cornerContainer={cornerContainer} lineContainer={lineContainer}>
                    <FinancialProductCards content="financial_products" iconsFolder="financial_products" />
                </ItemsSection>
                <HomepageCTA />
                <Opportunities />
                <Partners {...this.props} />
                <Clients {...this.props} />
                <Team quote={false} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Homepage);

