import { React } from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

const ContactInfo = props => {
    return (
        <section className='ContactInfo'>
            <div className='container section-padding'>
                <div className='content'>
                    <Row>
                        <Col md={6}>
                            <p className='city'>{props.t("contacts:info.location1.city")}</p>
                            <p className='address'><Trans t={props.t} i18nKey={props.t("contacts:info.location1.address")} /></p>
                            <Link className='icon-link d-flex' to="tel:+351 213 304 403">
                                <div className='icon-container'>
                                    <FontAwesomeIcon icon={faPhoneFlip} />
                                </div>
                                <div className='icon-text d-flex flex-column justify-content-center'>
                                    <Trans t={props.t} i18nKey={props.t("contacts:info.info-fix")}
                                        components={{ small: <small></small> }} />
                                </div>
                            </Link>
                            <Link className='icon-link d-none d-md-flex ' to="mailto:comercial@m-profiler.com">
                                <div className='icon-container'>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div className='icon-text d-flex flex-column justify-content-center'>
                                    <Trans t={props.t} i18nKey={props.t("contacts:info.info-email")}
                                        components={{ small: <small></small> }} />
                                </div>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <p className='city'>{props.t("contacts:info.location2.city")}</p>
                            <p className='address'><Trans t={props.t} i18nKey={props.t("contacts:info.location2.address")} /></p>
                            <Link className='icon-link d-flex' to="tel:+351 937 693 181">
                                <div className='icon-container'>
                                    <FontAwesomeIcon icon={faPhoneFlip} />
                                </div>
                                <div className='icon-text d-flex flex-column justify-content-center'>
                                    <Trans t={props.t} i18nKey={props.t("contacts:info.info-mobile")}
                                        components={{ small: <small></small> }} />
                                </div>
                            </Link>
                            <Link className='icon-link d-flex d-md-none' to="mailto:comercial@m-profiler.com">
                                <div className='icon-container'>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div className='icon-text d-flex flex-column justify-content-center'>
                                    <Trans t={props.t} i18nKey={props.t("contacts:info.info-email")}
                                        components={{ small: <small></small> }} />
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </section >
    )
}

export default ContactInfo;