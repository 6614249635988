import React, { Component } from 'react';
import Slider from "react-slick";
// import {NextArrow, PrevArrow} from "../Arrows/Arrows"

export default class Carousel extends Component {

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      autoplaySpeed: 8000,
      cssEase: "linear",
      className: "carousel",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="py-3 py-md-4">
        <Slider {...settings}>
          {this.props.children}
        </Slider>
      </div>
    );
  }
}