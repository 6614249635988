import React, { useEffect, useState, useRef } from 'react'
import Auxiliary from '../../../hoc/Auxiliary';
import LanguageChanger from '../LanguageChanger/LanguageChanger';
import { currentRouteKey } from '../../../shared/functions';
import { NavLink, useLocation } from 'react-router-dom'

import logo from '../../../assets/icons/logo-menu.svg';
import logoMobile from '../../../assets/icons/logo-menu-mobile.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import Droplink from '../DropLink/DropLink';

const Header = props => {

    const [currentKey, setCurrentKey] = useState()
    const [toggleMenu, setToggleMenu] = useState(false)
    const [subMenuToggle, setSubMenuToggle] = useState(null)
    const [menuHeight, setMenuHeight] = useState(false)

    const menuRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        let menuCriative = document.getElementById("menu-creative");
        if (subMenuToggle == null) {
            menuCriative.classList.remove("overflow")
        }
    })

    useEffect(() => {
        let subMenuActiveList = document.getElementsByClassName("m-0 nav-link active")
        let tabActive = document.getElementsByClassName("menu-item active")
        if (tabActive.length !== 0 && subMenuActiveList) {
            let category = tabActive[0].id.split("-")[0]
            let subMenu = document.getElementById(category)
            subMenu.classList.add("active")
            if (subMenuActiveList.length > 1) {
                Array.from(subMenuActiveList).forEach(item => {
                    if (item !== category) {
                        return item.classList.remove("active")
                    }
                });
            }
        } else if (tabActive.length === 0) {
            if (subMenuActiveList) {
                Array.from(subMenuActiveList).forEach(item => {
                    return item.classList.remove("active")
                });
            }
        }
    })

    useEffect(() => {
        let [key] = currentRouteKey(props.i18n)
        setCurrentKey(key)
    }, [location, props.i18n])

    return (
        <Auxiliary>
            <header>
                <nav className="h-md-100 w-100 d-flex flex-md-column justify-content-between align-items-center px-lg-4">
                    <NavLink onClick={() => setToggleMenu(false)} to={props.t('routes:homepage.path')} className='header-logo'>
                        <img src={logo} data-at2x={logo} className="fluid d-none d-md-block" alt="logo" />
                        <img src={logoMobile} data-at2x={logoMobile} className="fluid d-md-none" alt="logo" />
                    </NavLink>
                    {/* Menu Toogle */}
                    <div className="toggle-menu">
                        <div onClick={() => { setToggleMenu(!toggleMenu) }}>
                            <div className={toggleMenu ? "toggle-line active" : "toggle-line"}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    {/* Socias */}
                    <div className="header-socials d-none d-md-flex">
                        <a href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                    </div>

                    {/* Menu */}
                    <div id='menu-container' className={toggleMenu ? "header-navbar header-navbar-creative menu-show" : "header-navbar-creative header-navbar"}>
                        <div className="header-menu">
                            <div className="container-custom">
                                <div className="row align-items menu-item-center">
                                    <div className="col-md-8">
                                        <ul onMouseOver={() => setMenuHeight(menuRef.current.clientHeight)} id='menu-creative' ref={menuRef} className="menu menu-creative">
                                            <li className="menu-item text-uppercase">
                                                <NavLink id='homepage' onClick={() => { setToggleMenu(false); setSubMenuToggle(null) }} className="menu-link nav-link" to={props.t('routes:homepage.path')}>
                                                    {props.t('partials:menu.item.1')}
                                                </NavLink>
                                            </li>
                                            <li className="menu-item text-uppercase open-nav">
                                                <Droplink menuHeight={menuHeight} {...props} isOpen={subMenuToggle} setSubMenuToggle={setSubMenuToggle} setToggleMenu={setToggleMenu} name="services" tabName={props.t('partials:menu.item.2')} />
                                            </li>
                                            <li className="menu-item text-uppercase open-nav">
                                                <Droplink menuHeight={menuHeight} {...props} isOpen={subMenuToggle} setSubMenuToggle={setSubMenuToggle} setToggleMenu={setToggleMenu} name="products" tabName={props.t('partials:menu.item.3')} />
                                            </li>
                                            <li className="menu-item text-uppercase open-nav">
                                                <Droplink menuHeight={menuHeight} {...props} isOpen={subMenuToggle} setSubMenuToggle={setSubMenuToggle} setToggleMenu={setToggleMenu} name="solutions" tabName={props.t('partials:menu.item.4')} />
                                            </li>
                                            <li className="menu-item text-uppercase">
                                                <NavLink id='career' onClick={() => { setToggleMenu(false); setSubMenuToggle(null) }} className="menu-link nav-link" to={props.t('routes:jobs.path')}>
                                                    {props.t('partials:menu.item.5')}
                                                </NavLink>
                                            </li>
                                            <li className="menu-item text-uppercase">
                                                <a id='contacts' target='_blank' rel="noreferrer" onClick={() => { setToggleMenu(false); setSubMenuToggle(null) }} className="menu-link nav-link" href={props.t('routes:contacts.path')}>
                                                    {props.t('partials:menu.item.6')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LanguageChanger menu {...props} setToggleMenu={setToggleMenu}
                            onChangeLanguage={(lng) => props.onChangeLanguage(lng)}
                            currentLang={props.i18n.language}
                        />
                    </div>
                </nav>

            </header>
        </Auxiliary >
    );
}
export default Header;
