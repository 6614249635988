import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import ProductBanner from '../../components/ProductBanner/ProductBanner';
import ProductSection from '../../components/ProductSection/ProductSection';
import HighlightBanner from '../../components/HighlightBanner/HighlightBanner';

class Parc extends Component {
    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="parc" />
                <ProductBanner imgPadding {...this.props} page="parc" />
                <ProductSection noPadBottom noPadTitle {...this.props} facilities page="parc" section="description">
                    <TextGroup small noBtnPadding {...this.props} page="parc" section="description" />
                </ProductSection>
                <ProductSection noPadBottom {...this.props} facilities page="parc" section="destinations">
                    <TextGroup  {...this.props} page="parc" section="destinations" />
                </ProductSection>
                <ProductSection noPadTitle noPadTop noPadBottom noTitle  {...this.props} page="perceptum" section="functionalities">
                    <HighlightBanner {...this.props} location="products" page="parc" section="decisions">
                        <TextGroup noPadBottom {...this.props} page="parc" section="decisions" />
                    </HighlightBanner>
                </ProductSection>
                <ProductSection noPadBottom {...this.props} facilities page="parc" section="functionalities">
                    <TextGroup {...this.props} page="parc" section="functionalities" />
                </ProductSection>
                <ProductSection {...this.props} facilities page="parc" section="beneficts">
                    <TextGroup {...this.props} page="parc" section="beneficts" />
                    <TextGroup noPadBottom {...this.props} page="parc" section="demo" />
                </ProductSection>
            </Auxiliary>
        )
    }
}

export default withTranslation()(Parc);