import { React } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"

const HomepageCTA = props => {
    return (
        <section className='HomepageCTA'>
            <div className='container section-padding'>
                <Row className='p-0'>
                    <Col xs={12} md={6} className='d-flex flex-column align-items-center align-items-md-start justify-content-center px-3'>
                        <h2 className='cta-title text-uppercase'>{props.t('homepage:cta.title')}</h2>
                        <p className='cta-text'>{props.t('homepage:cta.text')}</p>
                        <a href={props.t('routes:contacts.path')} target='_blank' rel="noreferrer" className="btn">{props.t('homepage:cta.button')}</a>
                    </Col>
                    <Col xs={12} md={6} lg={5} className='d-flex flex-column align-items-center align-items-md-start justify-content-center justify-content-lg-start offset-lg-1 socials-container px-3 mt-4 mt-md-0'>
                        <p className='cta-follow-text text-uppercase'>{props.t('homepage:cta.follow')}</p>
                        <div className="cta-socials d-flex">
                            <a href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className='icon' icon={faLinkedinIn} />
                            </a>
                            <a href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className='icon' icon={faInstagram} />
                            </a>
                            <a href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className='icon' icon={faFacebookF} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className='icon' icon={faYoutube} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>

        </section>
    )

}

export default withTranslation()(HomepageCTA);