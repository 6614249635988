import { React, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import banner from "../../assets/images/services/banner/banner.png";

const ServiceBanner = props => {
    let bannerIcon = props.page ? props.product ? require('../../assets/icons/products/' + props.page + '/banner_' + props.page + '.svg') : require('../../assets/icons/services/' + props.page + '/banner_' + props.page + '.svg') : null;

    useEffect(() => {
        props.changeLanguageColor("white")
    }, [])

    return (
        <section className='ServiceBanner' style={{ backgroundImage: "url(" + banner + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "bottom 0 right 0" }} >
            <div className='content '>
                <div className='banner-title'>
                    <div className='d-flex align-items-center'>
                        {bannerIcon ? <Image fluid src={bannerIcon} /> : <></>}
                        <h1 className='m-0'><Trans t={props.t} i18nKey={props.t(props.page + ':banner.title')} /></h1>
                    </div>
                </div>
                <div className='banner-text'>
                    <p className='m-0'>
                        <Trans t={props.t} i18nKey={props.t(props.page + ':banner.text')}
                            components={{
                                LinkItSupport: <Link to={props.t('routes:it_support.path')}></Link>,
                                LinkWebApplication: <Link to={props.t('routes:web.path')}></Link>,
                                bold: <b></b>
                            }}
                        />
                    </p>
                    {props.i18n.exists(props.page + ':banner.btn') ? <a className='btn' target='_blank' rel="noreferrer" href={props.t("routes:contacts.path")}>{props.t(props.page + ':banner.btn')}</a> : <></>}
                    <div>
                        <div>
                            <section>
                                <div className="background-div" >
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        </section >
    )
}

export default ServiceBanner;