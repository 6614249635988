import { React } from 'react';
import { withTranslation } from 'react-i18next';

import FinancialProductCard from './FinancialProduct/FinancialProductCard';

const FinancialProductCards = props => {
    let FinancialProductsItems = Object.keys(props.i18n.getResource(props.i18n.language, 'homepage')[props.content]['cards']).map((key, index) => {
        return (
            <FinancialProductCard
                key={index}
                dashes={props.iconsFolder + '/dashes.svg'}
                title={props.t('homepage:' + props.content + '.cards.' + key + '.title')}
                text={props.t('homepage:' + props.content + '.cards.' + key + '.text')}
                videoID={props.t('homepage:' + props.content + '.cards.' + key + '.video_id')}
                btn={props.t('homepage:' + props.content + '.btn')}
                btnLink={key}
                left={index % 2 !== 0 ? true : false}
            />
        )
    })

    return (
        <section className='container FinancialProductCards px-0'>
            <div className='d-flex flex-column flex-lg-row'>
                {FinancialProductsItems}
            </div>
        </section>
    )
}

export default withTranslation()(FinancialProductCards);