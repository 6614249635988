import { React } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { Col, Image } from 'react-bootstrap';

const DetailsCard = props => {
    let dashes = props.dashes ? require('../../../assets/icons/products/' + props.dashes) : "";
    return (
        <Col xs={12} md={6} xl={4} className='text-content d-flex'>
            <div className='card-container'>
                <div className="semi-bdr-right"></div>
                <h2 className='number'>{props.number}</h2>
                <div className='content'>
                    <h3 className='text-uppercase'><Trans t={props.t} i18nKey={props.title} /></h3>
                    <p><Trans t={props.t} i18nKey={props.text} /></p>
                </div>
                <div className="semi-bdr-bottom"></div>
                <div className="bottom-box">
                    <div className="dashes">
                        <Image fluid src={dashes} />
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default withTranslation()(DetailsCard);