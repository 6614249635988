import React, { Component, Suspense } from "react";
import "./App.scss";
import i18n from "./i18n";

//import functions
import {
  currentRouteKey,
  getPathNewLang,
  shouldChangeLanguage,
  getLanguageByRoute,
} from "./shared/functions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";
import { withRouter } from "./shared/functions";

import { Routes, Route, Navigate, Link } from "react-router-dom";

//import containers
import Homepage from "./containers/Homepage/Homepage";
import Contacts from "./containers/Contacts/Contacts";
import JobOffers from "./containers/JobOffers/JobOffers";
import Header from "./components/Partials/Header/Header";
import CookiesBanner from "./components/Partials/CookiesBanner/CookiesBanner";
import Consultancy from "./containers/Services/Consultancy";
import Outsourcing from "./containers/Services/Outsourcing";
import EmbeddedRecruitment from "./containers/Services/EmbeddedRecruitment";
import Support from "./containers/Services/Support";
import CompanyCertification from "./containers/Services/CompanyCertification";
import Gdpr from "./containers/Services/Gdpr";
import Rgpc from "./containers/Services/Rgpc";
import LanguageChanger from "./components/Partials/LanguageChanger/LanguageChanger";
import JobOffer from "./containers/JobOffers/JobOffer/JobOffer";
import Footer from "./components/Partials/Footer/Footer";
import Carshare from "./containers/Products/Carshare";
import Perceptum from "./containers/Products/Perceptum";
import Otm from "./containers/Products/Otm";
import Meeting from "./containers/Products/Meeting";
import Consilium from "./containers/Products/Consilium";
import Smeap from "./containers/Products/Smeap";
import Web from "./containers/Products/Web";
import Software from "./containers/Products/Software";
import Mobile from "./containers/Products/Mobile";
import Facilities from "./containers/finantials/Facilities";
import Commercial from "./containers/finantials/Commercial";
import Billing from "./containers/finantials/Billing";
import Parc from "./containers/finantials/Parc";
import ScrollToTop from "./hoc/ScrollToTop";
import FixedSocials from "./components/UI/FixedSocials/FixedSocials";
import ThankYou from "./containers/ThankYou/ThankYou";

const Page404 = React.lazy(() => import('./containers/Page404/Page404'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: {
        en: i18n.getResource("en", "routes"),
        pt: i18n.getResource("pt", "routes"),
        fr: i18n.getResource("fr", "routes"),
      },
      old_routes: {
        en: i18n.getResource("en", "oldRoutes"),
        pt: i18n.getResource("pt", "oldRoutes"),
        fr: i18n.getResource("fr", "oldRoutes"),
      },
      language: null,
      color: null,
      redirectComponent: null
    };
  }

  componentDidMount() {
    //check if should change lang by verifying the routen
    if (shouldChangeLanguage(i18n)) {
      const routeLanguage = getLanguageByRoute(window.location.pathname, i18n);
      if (routeLanguage) {
        i18n.changeLanguage(routeLanguage);
        this.setState(
          {
            ...this.state,
            redirectComponent: <Navigate to={window.location.pathname} />,
            language: routeLanguage,
          },
          () => this.setState({ ...this.state, redirectComponent: null })
        );
      }
    }
    //fetch job offers
    if (!this.props.jobs || this.props.jobs.length === 0) {
      this.props.onTryFetchJobs();
    }
    Object.keys(this.state.old_routes).forEach(language => {
      Object.keys(this.state.old_routes[language]).forEach(page => {
        if (window.location.pathname === this.state.old_routes[language][page].path) {
          this.setState(
            { ...this.state, redirectComponent: <Navigate to={this.state.old_routes[language][page].redirect} /> },
            () => this.setState({ ...this.state, redirectComponent: null })
          );
        }
      })
    })
  }

  render() {
    //changeLanguage function
    const changeLanguage = (lng) => {
      //get the key of the current path
      let [currentKey, id] = currentRouteKey(i18n);

      if (!currentKey) {
        currentKey = "homepage";
      }
      //change language
      i18n.changeLanguage(lng);
      //get the path in new lang
      let newPath = getPathNewLang(i18n, lng, currentKey);

      if (id)
        newPath = newPath.includes(":id")
          ? newPath.replace(":id", id)
          : newPath.includes(":slug")
            ? newPath.replace(":slug", id)
            : newPath;

      this.setState(
        {
          ...this.state,
          redirectComponent: <Navigate to={newPath} />,
          language: lng,
        },
        () => this.setState({ ...this.state, redirectComponent: null })
      );
    };

    const changeLanguageColor = (color) => {
      this.setState(
        {
          ...this.state,
          color: null
        }, () => this.setState({ ...this.state, color: color })
      );
    };

    //Copy paste event
    document.addEventListener('copy', (event) => {
      const pagelink = '\n\n' + this.props.t('partials:copyText') + document.location.href;
      event.clipboardData.setData('text/plain', document.getSelection() + pagelink);
      event.preventDefault();
    });

    return (
      <div>
        {this.state.redirectComponent}
        {this.state.language && <LanguageChanger {...this.state}
          onChangeLanguage={(lng) => changeLanguage(lng)}
          currentLang={this.state.language} color={this.state.color} />}
        <Header {...this.props} onChangeLanguage={(lang) => changeLanguage(lang)} />
        <FixedSocials {...this.props} />
        <ScrollToTop>
          <Routes>
            {[
              this.state.routes.pt.homepage.path,
              this.state.routes.en.homepage.path,
              this.state.routes.fr.homepage.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Homepage changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.contacts.path,
              this.state.routes.en.contacts.path,
              this.state.routes.fr.contacts.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Contacts changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.jobs.path,
              this.state.routes.en.jobs.path,
              this.state.routes.fr.jobs.path,
            ].map((path) => (
              <Route key={path} path={path} element={<JobOffers changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
            ))}
            {[
              this.state.routes.pt.offer.path,
              this.state.routes.en.offer.path,
              this.state.routes.fr.offer.path
            ].map(path => (
              <Route key={path} path={path} element={<JobOffer changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
            ))}
            {/* services */}
            {[
              this.state.routes.pt.it_consultancy.path,
              this.state.routes.en.it_consultancy.path,
              this.state.routes.fr.it_consultancy.path,
            ].map((path) => (
              <Route key={path} path={path} element={<Consultancy changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
            ))}
            {[
              this.state.routes.pt.it_outsourcing.path,
              this.state.routes.en.it_outsourcing.path,
              this.state.routes.fr.it_outsourcing.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Outsourcing changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.embedded_recruitment.path,
              this.state.routes.en.embedded_recruitment.path,
              this.state.routes.fr.embedded_recruitment.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<EmbeddedRecruitment changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.it_support.path,
              this.state.routes.en.it_support.path,
              this.state.routes.fr.it_support.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Support changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.company_certification.path,
              this.state.routes.en.company_certification.path,
              this.state.routes.fr.company_certification.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<CompanyCertification changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.gdpr.path,
              this.state.routes.en.gdpr.path,
              this.state.routes.fr.gdpr.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Gdpr changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {/* Products */}
            {[
              this.state.routes.pt.perceptum.path,
              this.state.routes.en.perceptum.path,
              this.state.routes.fr.perceptum.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Perceptum changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.otm.path,
              this.state.routes.en.otm.path,
              this.state.routes.fr.otm.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Otm changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.meeting.path,
              this.state.routes.en.meeting.path,
              this.state.routes.fr.meeting.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Meeting changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.consilium.path,
              this.state.routes.en.consilium.path,
              this.state.routes.fr.consilium.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Consilium changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.carshare.path,
              this.state.routes.en.carshare.path,
              this.state.routes.fr.carshare.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Carshare changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.smeap.path,
              this.state.routes.en.smeap.path,
              this.state.routes.fr.smeap.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Smeap changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.software.path,
              this.state.routes.en.software.path,
              this.state.routes.fr.software.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Software changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.web.path,
              this.state.routes.en.web.path,
              this.state.routes.fr.web.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Web changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.mobile.path,
              this.state.routes.en.mobile.path,
              this.state.routes.fr.mobile.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Mobile changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.facilities.path,
              this.state.routes.en.facilities.path,
              this.state.routes.fr.facilities.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Facilities changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.commercial.path,
              this.state.routes.en.commercial.path,
              this.state.routes.fr.commercial.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Commercial changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.billing.path,
              this.state.routes.en.billing.path,
              this.state.routes.fr.billing.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Billing changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.parc.path,
              this.state.routes.en.parc.path,
              this.state.routes.fr.parc.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Parc changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.thank_you.path,
              this.state.routes.en.thank_you.path,
              this.state.routes.fr.thank_you.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<ThankYou />} exact />
              </>
            ))}
            {[
              this.state.routes.pt.rgpc.path,
              this.state.routes.en.rgpc.path,
              this.state.routes.fr.rgpc.path,
            ].map((path) => (
              <>
                <Route key={path} path={path} element={<Rgpc changeLanguageColor={(color) => changeLanguageColor(color)} />} exact />
              </>
            ))}
            {/* Page 404 */}
            <Route path="not-found" element={<Suspense><Page404 /></Suspense>} />
            <Route path="*" element={<Suspense><Page404 /></Suspense>} />
          </Routes>
        </ScrollToTop>
        <Footer {...this.props} onChangeLanguage={(lang) => changeLanguage(lang)} />
        <CookiesBanner {...this.props} />
        {/* Force crawl FR and EN routes */}
        <Link to={this.state.routes.fr.homepage.path} className="d-none" />
        <Link to={this.state.routes.en.homepage.path} className="d-none" />

        {/* Crawl thank you pages */}
        <Link to={this.state.routes.fr.contacts.path + "/" + this.state.routes.fr.thank_you.id} className="d-none" />
        <Link to={this.state.routes.pt.contacts.path + "/" + this.state.routes.pt.thank_you.id} className="d-none" />
        <Link to={this.state.routes.en.contacts.path + "/" + this.state.routes.en.thank_you.id} className="d-none" />
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    jobs: state.jobs.jobs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryFetchJobs: () => dispatch(actions.fetchJobs()),
  };
};

export default withRouter(
  connect(mapStateProps, mapDispatchToProps)(withTranslation()(App))
);
