import { React } from 'react';
import { Image } from 'react-bootstrap';

const ServiceSection = props => {
    let iconSection = props.section && !props.noImg ? require('../../assets/icons/services/' + props.page + '/' + props.section + '.svg') : null;
    if (props.noTitle) {
        return (
            <section className={props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadBottom noPadTop' : props.noPadBottom && !props.noPadTop ? 'ServiceSection section-padding noPadBottom' : !props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadTop' : 'ServiceSection section-padding'}>
                <div className='yellow-bar left'></div>
                <div className='yellow-bar right'></div>

                <div className='content'>
                    {props.children}
                </div>
            </section>
        )
    } else if (props.page === "companyCertification" && props.section === "certifications") {
        return (
            <section className={props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadBottom noPadTop' : props.noPadBottom && !props.noPadTop ? 'ServiceSection section-padding noPadBottom' : !props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadTop' : 'ServiceSection section-padding'}>
                <div className='yellow-bar left'></div>
                <div className='yellow-bar right'></div>
                <div className={props.noPadTitle ? 'certifications-title noPadTitle' : 'certifications-title '}>
                    <div className='title-conteiner container-custom px-0 px-0'>
                        <h2 className='title'>{props.t(props.page + ':' + props.section + '.title1')}</h2>
                    </div>
                </div>
                <div className='content'>
                    {props.children}
                </div>
            </section>
        )
    } else {
        return (
            <section className={props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadBottom noPadTop' : props.noPadBottom && !props.noPadTop ? 'ServiceSection section-padding noPadBottom' : !props.noPadBottom && props.noPadTop ? 'ServiceSection section-padding noPadTop' : 'ServiceSection section-padding'}>
                <div className='yellow-bar left'></div>
                <div className='yellow-bar right'></div>
                <div className={props.noPadTitle ? 'section-title container-custom mb-0' : 'section-title container-custom'}>
                    <div className='title-content'>
                        <figure className='d-none d-md-block'>
                            <Image src={iconSection} alt={props.i18n.exists("alt:" + props.page + "." + props.section) ? props.t("alt:" + props.page + "." + props.section) : ""} />
                        </figure>
                        <div className='title-conteiner'>
                            {/* title mobile */}
                            {props.i18n.exists(props.page + ':' + props.section + '.title3') ? <h2 className='title d-md-none'>{props.t(props.page + ':' + props.section + '.title1') + " " + props.t(props.page + ':' + props.section + '.title2') + " " + props.t(props.page + ':' + props.section + '.title3')}</h2> : <h2 className='title d-md-none'>{props.t(props.page + ':' + props.section + '.title1') + " " + props.t(props.page + ':' + props.section + '.title2')}</h2>}

                            {/* title desktop */}
                            <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title1')}</h2>
                            <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title2')}</h2>
                            {props.i18n.exists(props.page + ':' + props.section + '.title3') ? <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title3')}</h2> : <></>}
                        </div>
                    </div>
                </div>
                <div className='content'>
                    {props.children}
                </div>
            </section>
        )
    }


}

export default ServiceSection;