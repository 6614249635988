import { React } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import YouTube from 'react-youtube';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductCard = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const videoOptions = {
        playerVars: {
            autoplay: 1,
            rel: 0,
            showinfo: 0,
            loop: 0
        }
    };

    return (
        <Col className='p-2 card-container'>
            <div className='card-content d-flex flex-column justify-content-between'>
                <div>
                    <div className='title-container'>
                        <div className="video">
                            <div onClick={handleShow} className='icon-container'>
                                <FontAwesomeIcon className='icon' icon={faPlay} />
                            </div>
                        </div>
                        <h3 className='text-uppercase'>
                            <Trans t={props.t} i18nKey={props.title}
                                components={{ span: <span className='text-capitalize'></span> }} />
                        </h3>
                    </div>
                    <p>{props.text}</p>
                </div>
                <div className='more-container'>
                    <Link to={props.t('routes:' + props.btnLink + '.path')} className="btn">{props.btn}</Link>
                </div>

                <Modal className='modal-video' show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 d-flex align-items-center justify-content-center' >
                        <YouTube iframeClassName='iframe-video' style={{ width: "100%" }} videoId={props.videoID} opts={videoOptions} />
                    </Modal.Body>
                </Modal>
            </div>
        </Col>
    )
}

export default withTranslation()(ProductCard);