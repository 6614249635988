import { Trans } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { React, useEffect } from 'react';

import logo from '../../assets/icons/homepage/logo-home.svg';
import videoBanner from '../../assets/images/homepage/banner/video-home.mp4';
import videoBannerWeb from '../../assets/images/homepage/banner/banner.webm';

const HomeBanner = props => {

    useEffect(() => {
        props.changeLanguageColor("white")
    }, [])

    return (
        <section className='HomeBanner'>
            <div className='content'>
                <div className='content-container col-12 col-md-10 col-xl-11'>
                    <div className='floating-block'>
                        <div className='floating-block-content'>
                            <Image src={logo} />
                            <h1 className='text-uppercase'><Trans t={props.t} i18nKey={props.t('homepage:banner.title')} /></h1>
                            <p><Trans t={props.t} i18nKey={props.t('homepage:banner.text')} /></p>
                            <div className='btn-container'>
                                <a className="btn ltr-scg float-right" target="_blank" rel="noreferrer" href={props.t('routes:contacts.path')}>{props.t('homepage:banner.btn')}</a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <section>
                                    <div className="background-div" >
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='video-container' data-opacity="40" data-cover>
                    <video className='videoBanner d-none d-md-block' loop muted autoPlay>
                        <source src={videoBanner} type="video/mp4" />
                        <source src={videoBannerWeb} type="video/webm" />
                    </video>
                </div>

            </div>
        </section >
    )
}

export default HomeBanner;