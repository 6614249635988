import { React, useEffect, useState } from 'react';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { elementsToJson } from '../../shared/functions';
import * as actions from '../../store/actions/index';
import { BarLoader } from 'react-spinners';
import Modals from '../Modals/Modals';

import bgBanner from '../../assets/images/contacts/bg_contacts.png';
import { useNavigate } from 'react-router';

const ContactBanner = props => {

    useEffect(() => {
        props.changeLanguageColor("white")
    }, [])

    const [productDemo, setProductDemo] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [waitingBar, setWaitingBar] = useState(false);
    const [errorName, setErrorName] = useState({ show: false, message: props.t("contacts:form.errors.name.empty") });
    const [errorCompany, setErrorCompany] = useState({ show: false, message: props.t("contacts:form.errors.email.empty") });
    const [errorEmail, setErrorEmail] = useState({ show: false, message: props.t("contacts:form.errors.company.empty") });
    const [errorPhone, setErrorPhone] = useState({ show: false, message: props.t("contacts:form.errors.phone.empty") });
    const [errorMessage, setErrorMessage] = useState({ show: false, message: props.t("contacts:form.errors.message.empty") });
    const { submited } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        let product = new URLSearchParams(window.location.search)
        if (product.get("product") !== null) {
            setProductDemo(props.t("contacts:form.demos." + product.get("product")))
            let textarea = document.getElementById("message")
            var dataProduct = textarea.dataset.product;
            if (dataProduct && !textarea.value.includes(dataProduct)) {
                textarea.value = dataProduct;
            }
        } else {
            let textarea = document.getElementById("message")
            textarea.value = "";
        }
    }, [window.location.search, productDemo])

    useEffect(() => {
        if (props.submited) {
            setWaitingBar(false)
            props.onResetSubmit();
            navigate(props.t("routes:contacts.path") + "/" + props.t("routes:thank_you.id"));
        } else if (props.submited === false) {
            setWaitingBar(true)
        }
    }, [submited]);

    const checkFields = (e) => {
        // var dataProduct = e.target.attr('data-product');
        var dataProduct = e.target.dataset.product;
        if (dataProduct && !e.target.value.includes(dataProduct)) {
            e.target.value = dataProduct;
        }

        if (e.target.checkValidity()) {
            switch (e.target.id) {
                case "name":
                    setErrorName({ ...errorName, show: false })
                    break;
                case "company":
                    setErrorCompany({ ...errorCompany, show: false })
                    break;
                case "email":
                    setErrorEmail({ ...errorEmail, show: false })
                    break;
                case "phone":
                    setErrorPhone({ ...errorPhone, show: false })
                    break;
                case "message":
                    setErrorMessage({ ...errorMessage, show: false })
                    break;
                default:
                    break;
            }
        } else {
            let error = "";
            switch (e.target.id) {
                case "name":
                    if (e.target.value.length <= 0) {
                        error = props.t("contacts:form.errors.name.empty")
                    } else if (e.target.value.length < e.target.minLength)
                        error = props.t("contacts:form.errors.name.length")
                    else {
                        error = ""
                    }
                    setErrorName({ show: true, message: error })
                    break;
                case "company":
                    if (e.target.value.length <= 0) {
                        error = props.t("contacts:form.errors.company.empty")
                    } else if (e.target.value.length < e.target.minLength)
                        error = props.t("contacts:form.errors.company.length")
                    else {
                        error = ""
                    }
                    setErrorCompany({ show: true, message: error })
                    break;
                case "email":
                    if (e.target.value.length <= 0) {
                        error = props.t("contacts:form.errors.email.empty")
                    } else if (!e.target.checkValidity())
                        error = props.t("contacts:form.errors.email.valid")
                    else {
                        error = ""
                    }
                    setErrorEmail({ show: true, message: error })
                    break;
                case "phone":
                    if (e.target.value.length <= 0) {
                        error = props.t("contacts:form.errors.phone.empty")
                    } else if (!e.target.checkValidity())
                        error = props.t("contacts:form.errors.phone.valid")
                    else {
                        error = ""
                    }
                    setErrorPhone({ show: true, message: error })
                    break;
                case "message":
                    if (e.target.value.length <= 0) {
                        error = props.t("contacts:form.errors.message.empty")
                    } else if (e.target.value.length < e.target.minLength)
                        error = props.t("contacts:form.errors.message.length")
                    else {
                        error = ""
                    }
                    setErrorMessage({ show: true, message: error })
                    break;
                default:
                    break;
            }
        }
    }

    const checkTermsModal = () => {
        let submit = document.getElementById("submit_btn")
        submit.click()
    }

    const checkTerms = (event, form) => {
        if (isChecked) {
            //check if bot input is filled
            if (event.target.form.elements.honey && event.target.form.elements.honey.value !== '') {
                navigate(props.t("routes:contacts.path") + "/" + props.t("routes:thank_you.id"));
                return;
            }

            //submit data
            const _elements = elementsToJson(form.elements);

            submitForm(_elements);
        } else if (!isChecked) {
            handleShow()
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (e.target.form.checkValidity()) {
            checkTerms(e, e.target.form)
        } else {
            Array.from(e.target.form.elements).forEach(element => {
                switch (element.id) {
                    case "name":
                        setErrorName({ ...errorName, show: true })
                        break;
                    case "company":
                        setErrorCompany({ ...errorCompany, show: true })
                        break;
                    case "email":
                        setErrorEmail({ ...errorEmail, show: true })
                        break;
                    case "phone":
                        setErrorPhone({ ...errorPhone, show: true })
                        break;
                    case "message":
                        setErrorMessage({ ...errorMessage, show: true })
                        break;
                    default:
                        break;
                }
            });
        }
    }

    const navigate = useNavigate()

    const submitForm = (elements) => {
        if (elements.honey === "") {
            delete elements.honey;
            handleClose()
            setWaitingBar(true)
            props.onTrySubmitForm('contacts', elements);
        }
    }

    return (
        <section className='ContactBanner' style={{ backgroundImage: "url(" + bgBanner + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            <div className='section-padding-banner container'>
                <div className='title-container d-flex justify-content-center'>
                    {/* <div className='square d-none d-lg-block'>
                    </div> */}
                    <h1 className='ps-5 pe-5'>{props.t("contacts:banner.title1")}</h1>
                </div>
                <Row>
                    <Col sm={{ span: 10, offset: 1 }}>
                        <form id='contacts-form' className='contacts-form'>
                            {/* to detect bots */}
                            <input type="text" name="honey" id="honey" className="d-none" autoComplete="off" />
                            <Row>
                                <Col md={6} className='d-flex flex-column'>
                                    <OverlayTrigger
                                        trigger="manual"
                                        show={errorName.show}
                                        placement="top-end"
                                        overlay={<Tooltip className="tooltip-contact" id="tooltip">{errorName.message}</Tooltip>}>
                                        <input id='name' type='text' required minLength="3" placeholder={props.t("contacts:form.placeholders.name")} onInput={(e) => checkFields(e)} onKeyUp={(e) => checkFields(e)} />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="manual"
                                        show={errorCompany.show}
                                        placement="top-end"
                                        overlay={<Tooltip className="tooltip-contact" id="tooltip">{errorCompany.message}</Tooltip>}>
                                        <input id='company' type='text' required minLength="3" placeholder={props.t("contacts:form.placeholders.company")} onKeyUp={(e) => checkFields(e)} />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="manual"
                                        show={errorEmail.show}
                                        placement="top-end"
                                        overlay={<Tooltip className="tooltip-contact" id="tooltip">{errorEmail.message}</Tooltip>}>
                                        <input id='email' type='text' pattern="([A-Za-z0-9][._]?)+[A-Za-z0-9]@([A-Za-z0-9][.\-]?)+(\.?[A-Za-z0-9]){2}\.(com?|net|org)+(\.[A-Za-z0-9]{2,4})?" required placeholder={props.t("contacts:form.placeholders.email")} onKeyUp={(e) => checkFields(e)} />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="manual"
                                        show={errorPhone.show}
                                        placement="top-end"
                                        overlay={<Tooltip className="tooltip-contact" id="tooltip">{errorPhone.message}</Tooltip>}>
                                        <input id='phone' type='text' required minLength="9" maxLength="13" pattern="^[+\d](?:.*\d)?$" placeholder={props.t("contacts:form.placeholders.phone")} onKeyUp={(e) => checkFields(e)} />
                                    </OverlayTrigger>
                                </Col>
                                <Col md={6}>
                                    <OverlayTrigger
                                        trigger="manual"
                                        show={errorMessage.show}
                                        placement="top-end"
                                        overlay={<Tooltip className="tooltip-contact" id="tooltip">{errorMessage.message}</Tooltip>}>
                                        <textarea id='message' data-product={!productDemo ? "" : productDemo} minLength="10" required placeholder={props.t("contacts:form.placeholders.message")} onKeyUp={(e) => checkFields(e)}></textarea>
                                    </OverlayTrigger>
                                    <label htmlFor="terms" className="terms">
                                        <input type="checkbox" id="terms" name="terms" className="d-none" onKeyUp={(e) => checkFields(e)} />
                                        <span onClick={() => setIsChecked(!isChecked)} className={isChecked ? "checked checkmark elm-shadow" : "checkmark elm-shadow"}></span>
                                        <Modals  {...props} type="privacy" btn={props.t("contacts:form.terms")} />
                                    </label>
                                    <Modal className='modal-info' fullscreen={"lg-down"} show={show} onHide={handleClose} centered size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title><h3>{props.t("modals:check-rgpd.title")}</h3></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='p-0 d-flex align-items-center justify-content-center'>
                                            <div className='modal-content'>
                                                <p>{props.t('modals:check-rgpd.text')}</p>
                                                <label htmlFor="terms" className="terms">
                                                    <input type="checkbox" id="terms" name="terms" className="d-none" onKeyUp={(e) => checkFields(e)} />
                                                    <span onClick={() => setIsChecked(!isChecked)} className={isChecked ? "checked checkmark elm-shadow" : "checkmark elm-shadow"}></span>
                                                    <Modals  {...props} type="privacy" btn={props.t("contacts:form.terms")} />
                                                </label>
                                                {isChecked ? <div><button className="btn" type="submit" onClick={() => checkTermsModal()}>{props.t("contacts:form.btn")}</button></div> : ""}
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </Col>
                                <Col className='d-flex justify-content-center justify-content-md-end'>
                                    <div>
                                        <button className="btn" id='submit_btn' type="submit" onClick={(e) => handleSubmit(e)}>{props.t("contacts:form.btn")}</button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </div>
            <Modal animation={false} contentClassName='email-progress' show={waitingBar} centered>
                <div className='mt-3 d-flex justify-content-center align-items-center'>
                    <BarLoader height={6} width={120} color="#E31548" />
                </div>
            </Modal>
        </section >
    )
}

const mapStateProps = state => {
    return {
        submited: state.forms.submited
    }
}

const mapDispatchProps = dispatch => {
    return {
        onTrySubmitForm: (typeForm, info) => dispatch(actions.submitForm(typeForm, info)),
        onResetSubmit: () => dispatch(actions.resetSubmit()),
    }
}

export default connect(mapStateProps, mapDispatchProps)(ContactBanner);