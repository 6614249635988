import React, { useEffect, useState } from 'react'
import Auxiliary from '../../../hoc/Auxiliary';
import LanguageChanger from '../LanguageChanger/LanguageChanger';
import { currentRouteKey } from '../../../shared/functions';
import { useLocation } from 'react-router-dom'
import { Col, Image, Row } from 'react-bootstrap';
import FooterLinks from '../FooterLinks/FooterLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"

import logo from '../../../assets/icons/logo-footer.svg';
import { Trans } from 'react-i18next';
import Certifications from '../../Certifications/Certifications';

const Footer = props => {
    const currentYear = new Date().getFullYear();
    const [currentKey, setCurrentKey] = useState()
    const location = useLocation();

    useEffect(() => {
        let [key] = currentRouteKey(props.i18n)
        setCurrentKey(key)
    }, [location, props.i18n])

    return (
        <Auxiliary>
            <footer>
                <div className='container-custom'>
                    <Row className='footer-links d-flex d-md-none d-lg-flex'>
                        <Col sm={12} lg={3} className='column'>
                            <a href={props.t('routes:homepage.path')}>
                                <Image src={logo} className='logo' />
                            </a>
                        </Col>
                        <Col sm={12} lg={3} className='column'>
                            <div>
                                <FooterLinks {...props} column="column1" />
                            </div>
                        </Col>
                        <Col sm={12} lg={3} className='column'>
                            <div>
                                <FooterLinks {...props} column="column2" />
                            </div>
                        </Col>
                        <Col sm={12} lg={3} className='column'>
                            <div>
                                <FooterLinks {...props} column="column3" />
                            </div>
                        </Col>
                        <Col sm={12} className='column d-md-none'>
                            <div className="footer-socials d-flex d-md-none">
                                <a href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </a>
                                <a href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} />
                                </a>
                                <a href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row className='footer-links d-none d-md-flex d-lg-none'>
                        <Col md={6} className='column'>
                            <a href={props.t('routes:homepage.path')}>
                                <Image src={logo} className='logo' />
                            </a>
                            <div>
                                <FooterLinks {...props} column="column2" />
                            </div>
                        </Col>
                        <Col md={6} className='column'>
                            <div>
                                <FooterLinks {...props} column="column1" />
                            </div>
                            <div>
                                <FooterLinks {...props} column="column3" />
                            </div>
                        </Col>
                        <Col sm className='column'>
                            <div className="footer-socials d-flex d-md-none">
                                <a href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </a>
                                <a href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                                <a href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className='copy-right d-flex flex-column flex-md-row justify-content-between align-content-center'>
                        <Col sm={6}>
                            <p className='m-0 pb-2 pb-md-0'>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t('partials:footer.copyRight')}
                                    components={{
                                        LinkHome: <a href={props.t('routes:homepage.path')}></a>,
                                        HereAndNow: <a href="https://www.hereandnow.agency/" target='_blank' rel="noreferrer"></a>,
                                        year: `${currentYear}`
                                    }}
                                />
                            </p>
                        </Col>
                        <LanguageChanger {...props} footer
                            onChangeLanguage={(lng) => props.onChangeLanguage(lng)}
                            currentLang={props.i18n.language}
                        />
                    </div>
                </div>
                <Certifications {...props} />
            </footer>
        </Auxiliary >
    );
}
export default Footer;
