import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import YouTube from 'react-youtube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import quoteBackground from "../../assets/images/homepage/quote/bg_blue_quote.jpg"
import quoteImgEN from "../../assets/images/homepage/quote_video/en/quote_img.png"
import quoteImgPT from "../../assets/images/homepage/quote_video/pt/quote_img.png"

const QuoteVideo = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const videoOptions = {
        playerVars: {
            autoplay: 1,
            rel: 0,
            showinfo: 0,
            loop: 0
        }
    };
    return (
        <section className='QuoteVideo section-padding'>
            <div style={{ backgroundImage: "url(" + quoteBackground + ")" }} className="background-image"></div>
            <div className='container-custom'>
                <Row className='position-relative flex-row-reverse'>
                    <Col lg={6} className='p-0'>
                        <div className="team">
                            <Image fluid className='team-img' src={props.i18n.language === "pt" ? quoteImgPT : quoteImgEN} alt={props.t("alt:homepage.quote")} />
                            <div className="video-icon">
                                <div onClick={handleShow} className='icon-container'>
                                    <FontAwesomeIcon className='icon' icon={faPlay} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <h2>“{props.t('homepage:quoteVideo.quote')}”</h2>
                        <p className="lead">{props.t('homepage:quoteVideo.author')}</p>
                        <div className="cta">
                            <a rel="noreferrer" href={props.t("routes:contacts.path")} target="_blank" className="btn-creative ltr-scg">{props.t('homepage:quoteVideo.btn')}</a>
                        </div>
                    </Col>
                </Row>

            </div>

            <Modal className='modal-video' show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='p-0 d-flex align-items-center justify-content-center' >
                    <YouTube iframeClassName='iframe-video' style={{ width: "100%" }} videoId="Y3sDuTKMcdQ" opts={videoOptions} />
                </Modal.Body>

            </Modal>
        </section>
    )
}

export default withTranslation()(QuoteVideo);