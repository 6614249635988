import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { structuredDataJob } from '../../shared/structuredData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Link, Navigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const OfferDescription = props => {
    const [jobID, setJobID] = useState(null);
    const [allJobs, setallJobs] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [offerExists, setOfferExists] = useState(false);
    const [location, setLocation] = useState(null);
    const [workType, setWorkType] = useState(null);

    useEffect(() => {
        setJobID(window.location.pathname.split('/')[2])
    }, [])

    useEffect(() => {
        if (props.error) {
            setError(true)
            return;
        }

        if (props.jobs && props.jobs.length > 0 && !props.error) {
            setallJobs(props.jobs)
            setError(false)
            return;
        }
    }, [props.jobs])

    useEffect(() => {
        setJobToDisplay()
    }, [allJobs])

    const setJobToDisplay = () => {
        if (allJobs !== null && jobID) {
            let entries = Object.keys(allJobs);
            entries.map(key => {
                if (allJobs[key].id == jobID) {
                    setTitle(allJobs[key].jobTitle)
                    setDescription(allJobs[key].functionDescription)
                    setWorkType(allJobs[key].workType)
                    setLocation(allJobs[key].location)
                    props.onSetStructuredData(structuredDataJob(allJobs[key]));
                    setOfferExists(true)
                }
            })
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (description) {
            let newDescription = description.replace(new RegExp(/(\r\n|\n\r|\r|\n)/g), "</br>")
            let lines = newDescription.split("</br>")
            lines.map(line => {
                if (line.indexOf("O") === 0) {
                    line += '</br>';
                    newDescription = newDescription.replace(line, '</p><h4>' + line + '</h4><p>')
                    setDescription(newDescription.replace(new RegExp(/(<\/br>)+/g), "</br>"))
                }
            })
        }
    }, [description])

    return (
        <section className='OfferDescription'>
            <div className='container section-padding '>
                {isLoading ?
                    <div className='mt-3 d-flex justify-content-center align-items-center'>
                        <BarLoader />
                    </div> : error ?
                        <div className='mt-3 d-flex justify-content-center align-items-center'>
                            <h3>{props.t("job:details.errorOffers")}</h3>
                        </div>
                        : offerExists ? <div className='content'>
                            <div className='title-container'>
                                <h3>{title}</h3>
                            </div>
                            <div className='description-container'>
                                <p dangerouslySetInnerHTML={{ __html: description ? description.replace(new RegExp(/(\r\n|\n\r|\r|\n)/g), "</br>") : "" }} />
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className='socials'>
                                            <div>
                                                <Link className='icon-link' to={"mailto:?" + title + "&amp;Body=" + props.t("job:details.mail-body") + ":" + window.location.href}>
                                                    <div className='icon-container'>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </div>
                                                </Link>
                                                {/* <a className='icon-link' target='_new' href={"http://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href}>
                                            <div className='icon-container'>
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </div>
                                        </a> */}
                                                <Link className='icon-link' target='_new' to={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}>
                                                    <div className='icon-container'>
                                                        <FontAwesomeIcon icon={faFacebookF} />
                                                    </div>
                                                </Link>
                                                <Link className='icon-link' onClick={() => { navigator.clipboard.writeText(window.location.href) }}>
                                                    <div className='icon-container'>
                                                        <FontAwesomeIcon icon={faLink} />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <a href={"https://jobs.m-profiler.com/Candidatura?recruitmentRequestId=" + jobID} rel="noreferrer" target='_blank' className="btn">{props.t('job:form')}</a>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Link to={props.t('routes:jobs.path')} className="btn">{props.t('job:return')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div> : <Navigate replace={true} to="/not-found" />
                }
            </div>
        </section >
    )
}
const mapStateProps = state => {
    return ({
        jobs: state.jobs.jobs,
        error: state.jobs.error
    });
}

const mapDispatchProps = dispatch => {
    return {
        onSetStructuredData: (data) => dispatch(actions.setJobStructuredData(data)),
    }
}

export default connect(mapStateProps, mapDispatchProps)(OfferDescription);