import { React } from 'react';
import { Image } from 'react-bootstrap';

const ProductSection = props => {
    let iconSection = !props.noImg ? props.page && props.section && !props.facilities ? require('../../assets/icons/products/' + props.page + '/' + props.section + '.svg') : require('../../assets/icons/finantials/' + props.page + '/' + props.section + '.svg') : null;

    if (props.noTitle) {
        return (
            <section className={props.noPadBottom && props.noPadTop ? 'ProductSection section-padding noPadBottom noPadTop' : props.noPadBottom && !props.noPadTop ? 'ProductSection section-padding noPadBottom' : !props.noPadBottom && props.noPadTop ? 'ProductSection section-padding noPadTop' : 'ProductSection section-padding'}>
                <div className={props.yellowBar ? 'blue-bar yellow left' : 'blue-bar left'}></div>
                <div className={props.yellowBar ? 'blue-bar yellow right' : 'blue-bar right'}></div>
                <div className={props.noPadTitle ? 'section-title container-custom mb-0' : 'section-title container-custom'}>
                </div>
                <div className='content'>
                    {props.children}
                </div>
            </section>
        )
    } else {
        return (
            <section className={props.noPadBottom && props.noPadTop ? 'ProductSection section-padding noPadBottom noPadTop' : props.noPadBottom && !props.noPadTop ? 'ProductSection section-padding noPadBottom' : !props.noPadBottom && props.noPadTop ? 'ProductSection section-padding noPadTop' : 'ProductSection section-padding'}>
                <div className={props.yellowBar ? 'blue-bar yellow left' : 'blue-bar left'}></div>
                <div className={props.yellowBar ? 'blue-bar yellow right' : 'blue-bar right'}></div>
                <div className={props.noPadTitle ? 'section-title container-custom mb-0' : 'section-title container-custom'}>
                    <div className='title-content d-flex justify-content-center justify-content-md-start align-content-center'>
                        <figure className='d-none d-md-block'>
                            <Image src={iconSection} />
                        </figure>
                        <div className='title-conteiner '>
                            {/* title mobile */}
                            {props.i18n.exists(props.page + ':' + props.section + '.title3') ? <h2 className='title d-md-none'>{props.t(props.page + ':' + props.section + '.title1') + " " + props.t(props.page + ':' + props.section + '.title2') + " " + props.t(props.page + ':' + props.section + '.title3')}</h2> : <h2 className='title d-md-none'>{props.t(props.page + ':' + props.section + '.title1') + " " + props.t(props.page + ':' + props.section + '.title2')}</h2>}

                            {/* title desktop */}
                            <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title1')}</h2>
                            <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title2')}</h2>
                            {props.i18n.exists(props.page + ':' + props.section + '.title3') ? <h2 className='title d-none d-md-flex'>{props.t(props.page + ':' + props.section + '.title3')}</h2> : <></>}
                        </div>
                    </div>
                </div>
                <div className='content'>
                    {props.children}
                </div>
            </section>
        )
    }

}

export default ProductSection;