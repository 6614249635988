import { React, useEffect } from 'react';
import { Trans } from 'react-i18next';

import bgBanner from '../../assets/images/career/bg_career2.png';

const CareerBanner = props => {

    useEffect(() => {
        props.changeLanguageColor("white")
    }, [])

    return (
        <section className='careerBanner' style={{ backgroundImage: "url(" + bgBanner + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center top 0" }}>
            <div className='content'>
                <div className='content-container col-12 col-md-10 col-xl-11'>
                    <div className='floating-block'>
                        <div className='floating-block-content'>
                            <h1 className='text-uppercase'><Trans t={props.t} i18nKey={props.t('jobs:banner.title')} /></h1>
                            <h2><Trans t={props.t} i18nKey={props.t('jobs:banner.sub-title')} /></h2>
                            <p className='m-0'><Trans t={props.t} i18nKey={props.t('jobs:banner.text')} /></p>
                        </div>
                        <div>
                            <div>
                                <section>
                                    <div className="background-div" >
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default CareerBanner;