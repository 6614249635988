import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import Partner from './Parther/Parther';

const Partners = props => {
    let PartnerItems = Object.keys(props.i18n.getResource(props.i18n.language, 'homepage')["parthers"]['parther_list']).map((key) => {
        return (
            <Partner
                key={key}
                id={key}
                title={props.t('homepage:parthers.parther_list.' + key + ".title")}
                text={props.t('homepage:parthers.parther_list.' + key + ".text")}
                icon={"parthers/" + key + ".svg"}
            />
        )
    })

    return (
        <section className='Parthers section-padding'>
            <div className='container-custom'>
                <div className='d-none d-lg-block square-icon'>
                </div>
                <div className='title-container'>
                    <div className='title col-12 col-lg-9 px-3'>
                        <div className='title-desktop d-none d-md-block'>
                            <h2 className='text-uppercase'>{props.t('homepage:parthers.title1')}</h2>
                            <h2 className='text-uppercase'>{props.t('homepage:parthers.title2')}</h2>
                        </div>
                        <div className='title-mobile d-md-none'>
                            <h2 className='text-uppercase m-0'>{props.t('homepage:parthers.title1') + " " + props.t('homepage:parthers.title2')}</h2>
                        </div>
                    </div>
                </div>
                <Row>
                    {PartnerItems}
                    <Col md={6} className='idea-card'>
                        <div className='content'>
                            <div>
                                <h4 className='text-uppercase'>{props.t('homepage:parthers.title_idea')}</h4>
                                <p>{props.t('homepage:parthers.text_idea')}</p>
                                <a href={props.t('routes:contacts.path')} target='_blank' rel="noreferrer" className="btn w-100">{props.t('homepage:parthers.btn')}</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default withTranslation()(Partners);