import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TextGroup from '../../components/TextGroup/TextGroup';
import CTA from '../../components/CTA/CTA';
class Support extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="support" />
        <ServiceBanner {...this.props} page="support" />
        <ServiceSection noPadTitle noPadBottom {...this.props} page="support" section="importance">
          <TextGroup {...this.props} page="support" section="importance" />
        </ServiceSection>
        <ServiceSection noPadBottom {...this.props} page="support" section="advantage">
          <TextGroup {...this.props} page="support" section="advantage" />
        </ServiceSection>
        <ServiceSection noPadBottom {...this.props} page="support" section="services">
          <TextGroup {...this.props} page="support" section="services" />
        </ServiceSection>
        <ServiceSection noPadBottom {...this.props} page="support" section="assistance">
          <TextGroup {...this.props} page="support" section="assistance" />
        </ServiceSection>
        <ServiceSection noTitle {...this.props} page="outsourcing">
          <CTA noBG {...this.props} />
        </ServiceSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(Support);