import { React } from 'react';
import { withTranslation } from 'react-i18next';

import bgCTA from "../../assets/images/services/cta/bg_cta.png"
import bgCTAAmarelo from "../../assets/images/products/cta/bg_cta_products.png"

const CTA = props => {
    if (props.noBG) {
        return (
            <section className='CTA noBG'>
                <div className='container-custom  content d-flex flex-column align-items-center justify-content-between'>
                    <h2 className='text-uppercase text-center'>{props.t('cta:title')}</h2>
                    <a className='btn' target='_blank' rel="noreferrer" href={props.t("routes:contacts.path")}>{props.t('cta:btn')}</a>
                </div>
            </section>
        )
    } else {
        return (
            <section style={{ backgroundImage: props.bgYellow ? "url(" + bgCTAAmarelo + ")" : "url(" + bgCTA + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className='CTA section-padding'>
                <div className='container content d-flex flex-column flex-xl-row align-items-center align-items-md-start align-items-xl-center justify-content-around'>
                    <h2 className='text-uppercase text-center text-xl-start'>{props.t('cta:title')}</h2>
                    <a className='btn' target='_blank' rel="noreferrer" href={props.t("routes:contacts.path")}>{props.t('cta:btn')}</a>
                </div>
            </section>
        )
    }

}

export default withTranslation()(CTA);