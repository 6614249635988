import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';

import Background from "../../assets/images/homepage/clients/blue_backgorund.jpg"
import advancedCare from "../../assets/images/homepage/clients/advance.png"
import axians from "../../assets/images/homepage/clients/axians.png"
import cascais from "../../assets/images/homepage/clients/cascais.png"
import openSoft from "../../assets/images/homepage/clients/open.png"
import rigor from "../../assets/images/homepage/clients/rigor.png"
import critical from "../../assets/images/homepage/clients/critical.png"
import euroBic from "../../assets/images/homepage/clients/euroBic.png"
import Carousel from '../UI/Carousel/Carousel';
// const Carousel = React.lazy(() => import('../UI/Carousel/Carousel'));

const Clients = props => {

    return (
        <section style={{ backgroundImage: "url(" + Background + ")" }} className='Clients section-padding'>
            <div className='container'>
                <Col lg={10} className='title-container'>
                    <div>
                        <h2>{props.t('homepage:clients.title1')}</h2>
                        <h2>{props.t('homepage:clients.title2')}</h2>
                    </div>
                </Col>
                <Row className='clients d-none d-lg-flex m-0'>
                    <Col>
                        <Image fluid src={advancedCare} alt={props.t("alt:homepage.clients.advance")} />
                    </Col>
                    <Col>
                        <Image fluid src={axians} alt={props.t("alt:homepage.clients.axians")} />
                    </Col>
                    <Col>
                        <Image fluid src={cascais} alt={props.t("alt:homepage.clients.ambiente")} />
                    </Col>
                    <Col>
                        <Image fluid src={openSoft} alt={props.t("alt:homepage.clients.opensoft")} />
                    </Col>
                    <Col>
                        <Image fluid src={rigor} alt={props.t("alt:homepage.clients.rigor")} />
                    </Col>
                    <Col>
                        <Image fluid src={critical} alt={props.t("alt:homepage.clients.critical")} />
                    </Col>
                    <Col>
                        <Image fluid src={euroBic} alt={props.t("alt:homepage.clients.euroBic")} />
                    </Col>
                </Row>
                <div className='clients d-lg-none'>
                    <Carousel>
                        <div>
                            <Image fluid src={advancedCare} alt={props.t("alt:homepage.clients.advance")} />
                        </div>
                        <div>
                            <Image fluid src={axians} alt={props.t("alt:homepage.clients.axians")} />
                        </div>
                        <div>
                            <Image fluid src={cascais} alt={props.t("alt:homepage.clients.ambiente")} />
                        </div>
                        <div>
                            <Image fluid src={openSoft} alt={props.t("alt:homepage.clients.opensoft")} />
                        </div>
                        <div>
                            <Image fluid src={rigor} alt={props.t("alt:homepage.clients.rigor")} />
                        </div>
                        <div>
                            <Image fluid src={critical} alt={props.t("alt:homepage.clients.critical")} />
                        </div>
                        <div>
                            <Image fluid src={euroBic} alt={props.t("alt:homepage.clients.euroBic")} />
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(Clients);