import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';

import IconCard from './IconCard/IconCard';

const IconCards = props => {
    let iconItems = Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]['content']).map((key, index) => {
        return (
            <IconCard
                key={index}
                products={props.products ? true : false}
                icon={props.page + '/' + key}
                title={props.t(props.page + ':' + props.section + '.content.' + key + '.title')}
            />
        )
    })

    return (
        <div className={props.products ? 'container-custom IconCards' : 'container IconCards p-0'}>
            <Row className='justify-content-between'>
                {iconItems}
            </Row>
        </div>
    )
}

export default withTranslation()(IconCards);