
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import PageNotFound from '../../components/PageNotFound/PageNotFound';

class Page404 extends Component {
    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="404" />
                <PageNotFound {...this.props} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Page404)