import { React } from 'react';

const HighlightBanner = props => {

    return (
        <section className={props.noPadBottom ? 'HighlightBanner  noPadBottom container' : 'HighlightBanner container'}>
            <div className="title-content">
                <div className='title-conteiner ps-3'>
                    <h2 className='title d-flex'>{props.t(props.page + ':' + props.section + '.title')}</h2>
                </div>
            </div>
            <div className='content'>
                {props.children}
            </div>
        </section >
    )
}

export default HighlightBanner;