import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TextGroup from '../../components/TextGroup/TextGroup';
import Video from '../../components/Video/Video';
import CTA from '../../components/CTA/CTA';
class Consultancy extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="consultancy" />
        <ServiceBanner {...this.props} page="consultancy" />
        <ServiceSection noPadTitle noTitle noPadBottom {...this.props} page="consultancy" >
          <Video {...this.props} page="consultancy" />
        </ServiceSection>
        <ServiceSection noPadBottom {...this.props} page="consultancy" section="services">
          <TextGroup {...this.props} page="consultancy" section="services" />
        </ServiceSection>
        <ServiceSection noPadBottom noPadTitle {...this.props} page="consultancy" section="benefits">
          <TextGroup {...this.props} page="consultancy" section="benefits" />
        </ServiceSection>
        <ServiceSection noPadBottom noPadTitle {...this.props} page="consultancy" section="products">
          <TextGroup {...this.props} page="consultancy" section="products" />
        </ServiceSection>
        <ServiceSection noTitle {...this.props} page="consultancy">
          <CTA noBG {...this.props} />
        </ServiceSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(Consultancy);