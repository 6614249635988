import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const FixedSocials = props => {

    const [socialsOpen, setSocialsOpen] = useState(false)

    return (
        <div className='FixedSocials d-md-none'>
            <div className={socialsOpen ? 'toggle-container active' : 'toggle-container'} onClick={() => setSocialsOpen(!socialsOpen)}>
                <FontAwesomeIcon className='icon' icon={faPlus} />
                <div className={socialsOpen ? 'content d-flex flex-column align-items-center open' : 'content d-flex flex-column align-items-center'}>
                    <a href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
            </div>
        </div>
    );

}

export default FixedSocials;