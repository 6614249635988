import { React, useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/customScroll.css";
import { connect } from 'react-redux';

import searchIcon from '../../assets/icons/jobs/icon-search.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { BarLoader } from 'react-spinners';

const Offers = props => {
    const [displayJobsList, setDisplayJobsList] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [error, seterror] = useState(null)
    const [allJobs, setallJobs] = useState(null);
    const [workspace, setWorkspace] = useState("");
    const [location, setLocation] = useState("");

    useEffect(() => {
        setJobsToDisplay(props.jobs)
        setallJobs(props.jobs)
    }, [props.jobs])

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
    }, [props.error])

    const search = e => {
        e.preventDefault();
        /* check what input is reciving values */
        if (e.target.id === "searchWork") {
            setWorkspace(e.target.value)
        } else if (e.target.id === "searchLocality") {
            setLocation(e.target.value)
        }

        if (allJobs !== null) {
            let final = Object.keys(allJobs).filter((item) => {
                /* check if there is a job title */
                if (allJobs[item]["jobTitle"] && allJobs[item]["functionDescription"]) {
                    /* check if the location input is not empty and if there is a city name in allJobs value */
                    if (location !== "" && allJobs[item]["location"]) {
                        /* load content that only includes the location value */
                        if (location !== "" && workspace === ""
                            && allJobs[item]["location"].toLowerCase().includes(location.toLowerCase())) {
                            return allJobs
                        }
                        /* load content that includes both location and workspace values */
                        else if (workspace !== "" && location !== ""
                            && allJobs[item]["location"].toLowerCase().includes(location.toLowerCase())
                            && allJobs[item]["jobTitle"].toLowerCase().includes(workspace.toLowerCase())) {
                            return allJobs
                        }
                        else if (workspace !== "" && location !== ""
                            && allJobs[item]["jobTitle"].toLowerCase().includes(workspace.toLowerCase())
                            && location.toLowerCase() === "lisboa"
                            && (allJobs[item]["location"].toLowerCase() === "são jorge de arroios"
                                || allJobs[item]["location"].toLowerCase() === "lisboa")) {
                            return allJobs
                        }
                        else if (location !== "" && workspace === ""
                            && location.toLowerCase() === "lisboa"
                            && (allJobs[item]["location"].toLowerCase() === "são jorge de arroios"
                                || allJobs[item]["location"].toLowerCase() === "lisboa")) {
                            return allJobs
                        }
                    }
                    /* loads all content */
                    if (workspace === "" && location === "") {
                        return allJobs
                    }
                    /* loads content that icludes the workspace value */
                    else if (workspace !== "" && location === ""
                        && allJobs[item]["jobTitle"].toLowerCase().includes(workspace.toLowerCase())) {
                        return allJobs
                    }
                }
                /* if the element dont have jobTitle return false */
                else return false
            }).map((jobs, index) => {
                return (
                    <div className='offer-container'>
                        <div className='offer d-flex justify-content-between'>
                            <h3 className='mb-0' key={index}>{allJobs[jobs]["jobTitle"]}</h3>
                            <a target="_blank" rel="noreferrer" href={props.t('routes:jobs.path') + '/' + allJobs[jobs]["id"]} className='d-flex justify-content-center align-items-center info-btn'>
                                <FontAwesomeIcon className='plus-btn' icon={faPlus} />
                                <p className='m-0'>{props.t('jobs:opportunities.info')}</p>
                                <FontAwesomeIcon className='arrow-btn' icon={faArrowRight} />
                            </a>
                        </div>
                    </div>
                )
            })
            setDisplayJobsList(final)
        }
    };

    const setJobsToDisplay = (jobs) => {
        if (jobs) {
            seterror(false)
            setisLoading(false)
            let final = Object.keys(jobs).map((job, index) => {
                if (jobs[job]["jobTitle"] && jobs[job]["functionDescription"]) {
                    return (
                        <div key={index} className='offer-container'>
                            <div className='offer d-flex justify-content-between'>
                                <h3 className='mb-0'>{jobs[job]["jobTitle"]}</h3>
                                <a target="_blank" rel="noreferrer" href={props.t('routes:jobs.path') + '/' + jobs[job]["id"]} className='d-flex justify-content-center align-items-center info-btn'>
                                    <FontAwesomeIcon className='plus-btn' icon={faPlus} />
                                    <p className='m-0'>{props.t('jobs:opportunities.info')}</p>
                                    <FontAwesomeIcon className='arrow-btn' icon={faArrowRight} />
                                </a>
                            </div>
                        </div>
                    )
                }
                else return false

            })
            setDisplayJobsList(final)
        }
    }
    return (
        <section className='Offers section-padding'>
            <div className='content container px-lg-0'>
                <div className='info-container'>
                    <Row>
                        <div className='title'>
                            <h2 className=''>{props.t('jobs:opportunities.title1') + " " + props.t('jobs:opportunities.title2')}</h2>
                        </div>
                    </Row>
                    <div className='d-flex justify-content-center'>
                        <a target='_blank' rel="noreferrer" className='btn text-uppercase' href={"https://jobs.m-profiler.com/Candidatura"}>{props.t("jobs:opportunities.btn")}</a>
                    </div>
                </div>
                <Row className='search-container'>
                    <Col md={1} className='d-none d-md-block position-relative px-0'>
                        <Image className='search-icon' fluid src={searchIcon} />
                    </Col>
                    <Col className='input-container' md={6}>
                        <input id="searchWork" onKeyUp={(e) => search(e)} onInput={(e) => search(e)} placeholder={props.t('jobs:opportunities.search-work')} />
                    </Col>
                    <Col className='input-container' md={5}>
                        <input id="searchLocality" onKeyUp={(e) => search(e)} onInput={(e) => search(e)} placeholder={props.t('jobs:opportunities.search-locality')} />
                    </Col>
                </Row>

                {error ?
                    <div className='mt-3 d-flex justify-content-center align-items-center'>
                        <BarLoader />
                    </div>
                    : isLoading ? "" : displayJobsList && displayJobsList.length <= 0 ?
                        <div className='search-error'>
                            <h4 className='mb-0 font-weight-700'>{props.t("jobs:opportunities.search-error")}</h4>
                        </div>
                        :
                        <div>
                            <CustomScroll className='offer-list ' heightRelativeToParent='420px'>
                                {displayJobsList}
                            </CustomScroll>
                            <div className='quote mt-5'>
                                <i><h3 className='h2 text-center'>“{props.t("jobs:offer.quote.text")}”</h3></i>
                                <i><p className='m-0 text-center'>{props.t("jobs:offer.quote.author")}</p></i>
                            </div>
                        </div>
                }
            </div>
        </section >
    )
}
const mapStateProps = state => {
    return ({
        jobs: state.jobs.jobs,
        error: state.jobs.error
    });
}
export default (connect(mapStateProps)(Offers));