import { React } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import bg404 from '../../assets/images/404/bg_404.png';
import img404 from '../../assets/images/404/404.svg';

const PageNotFound = props => {
    return (
        <section style={{ backgroundImage: "url(" + bg404 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className='PageNotFound'>
            <div className='content section-container'>
                <div className='image'>
                    <Image fluid src={img404} />
                </div>
                <div className='text'>
                    <h2>{props.t("page404:title1")}</h2>
                    <h2>{props.t("page404:title2")}</h2>
                    <Link className='btn' to={props.t("routes:homepage.path")}>{props.t("page404:btn")}</Link>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound;