import { React } from 'react';
import { withTranslation } from 'react-i18next';

import { Row } from 'react-bootstrap';

import ServiceCard from './ServiceCard/ServiceCard';

const ServiceCards = props => {
    let servicesItems = Object.keys(props.i18n.getResource(props.i18n.language, 'homepage')[props.content]['cards']).map((key, index) => {
        return (
            <ServiceCard
                key={index}
                id={key}
                icon={props.iconsFolder + '/' + key + '.svg'}
                dashes={props.iconsFolder + '/dashes.svg'}
                title={props.t('homepage:' + props.content + '.cards.' + key + '.title')}
                text={props.t('homepage:' + props.content + '.cards.' + key + '.text')}
                btn={props.t('homepage:' + props.content + '.btn')}
                btnLink={key}
            />
        )
    })

    return (
        <div className='container ServiceCards'>
            <Row>
                {servicesItems}
            </Row>
        </div>
    )
}

export default withTranslation()(ServiceCards);