import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import YouTube from 'react-youtube';

const Video = props => {

    const opts = {
        playerVars: {
            controls: 0,
            rel: 0
        },
    };

    return (
        <section className='container'>
            <div >
                <YouTube className='container-video' iframeClassName='video' videoId={props.t(props.page + ':banner.videoId')} opts={opts} />
            </div>
        </section>
    )
}

export default withTranslation()(Video);