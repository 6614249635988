import * as actionsTypes from "./actionsTypes";

export const submitForm = (typeForm, info) => {
    return {
        type: actionsTypes.SUBMIT_FORM,
        typeForm: typeForm,
        info: info
    }
}

export const sucessSubmitForm = () => {
    return {
        type: actionsTypes.FORM_SUBMIT_SUCCESS,
    }
}

export const failSubmitForm = () => {
    return {
        type: actionsTypes.FORM_SUBMIT_ERROR,
    }
}

export const resetSubmit = () => {
    return {
        type: actionsTypes.RESET_SUBMIT,
    }
}