import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import { withTranslation } from 'react-i18next';
import CareerBanner from '../../components/CareerBanner/CareerBanner';
import Offers from '../../components/Offers/Offers';
import CareerInfo from '../../components/CareerInfo/CareerInfo';
import TextGroup from '../../components/TextGroup/TextGroup';
import IconCards from '../../components/IconCards/IconCards';


const JobOffers = (props) => {
    return (
        <Auxiliary>
            <Head {...props} id="jobOffers" />
            <CareerBanner {...props} />
            <Offers {...props} />
            <CareerInfo noPadBottom {...props} section="offer">
                <TextGroup {...props} page="jobs" section="offer" />
            </CareerInfo>
            <CareerInfo {...props} section="recruitment">
                <TextGroup {...props} page="jobs" section="recruitment" />
                <IconCards {...props} page="jobs" section="icons" />
            </CareerInfo>
        </Auxiliary>
    )
}

export default withTranslation()(JobOffers)