import axios from "axios";


export const instanceMlog = axios.create({
    baseURL: 'http://mlogapi.m-profiler.com',
    headers: { "content-type": "application/json" },
    json: {
        "username": "cnorberto",
        "password": "mlogMP_mkt123"
    }
});

export const instance_API_AWS = axios.create({
    baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/test',
    // baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/prod',
    headers: {
        'x-api-key': 'ev4nLoS6Gz6ev67rRj0BBaEWzriDCJQG4sbmpZnS',
    }
});
