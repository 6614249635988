import { React, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modals from '../../Modals/Modals';

import ppr_PT from "../../../assets/files/Plano-de-Prevencao-de-Riscos-de-Corrupcao.pdf";
import ppr_EN from "../../../assets/files/Corruption-Risk-Prevention-Plan.pdf";
import ppr_anual_PT from "../../../assets/files/Relatorio-Execucao-Anual-PPR-2023.pdf";

const FooterLinks = props => {
    return (
        <div>
            <ul>
                {Object.keys(props.i18n.getResource(props.i18n.language, 'partials')['footer'][props.column]).map((item) => {
                    if (props.column === "column1") {
                        if (item === "ppr") {
                            if (props.i18n.language === "pt") {
                                return <li className="footer-link"> <a target='_blank' rel="noreferrer" href={ppr_PT}>
                                    <p className='m-0'>{props.t("partials:footer.column1.ppr")}</p>
                                </a></li>
                            } else {
                                return <li className="footer-link"><a target='_blank' rel="noreferrer" href={ppr_EN}>
                                    <p className='m-0'>{props.t("partials:footer.column1.ppr")}</p>
                                </a></li>
                            }
                        } else if (item === "ppr_anual") {
                            if (props.i18n.language === "pt") {
                                return <li className="footer-link"> <a target='_blank' rel="noreferrer" href={ppr_anual_PT}>
                                    <p className='m-0'>{props.t("partials:footer.column1.ppr_anual")}</p>
                                </a></li>
                            } else {
                                return <li className="footer-link"><a target='_blank' rel="noreferrer" href={ppr_anual_PT}>
                                    <p className='m-0'>{props.t("partials:footer.column1.ppr_anual")}</p>
                                </a></li>
                            }
                        } else {
                            return <Modals key={item} {...props} type={item} btn={props.t("partials:footer." + props.column + "." + item)} />
                        }
                    } else {
                        return (
                            <li key={item} className="footer-link">
                                <Link to={item === "nearshoring" ? "https://www.nearshorit.com/" : props.t("routes:" + item + ".path")} target={item === "nearshoring" ? '_blank' : "_self"}>
                                    <Trans
                                        t={props.t}
                                        i18nKey={props.t('partials:footer.' + props.column + '.' + item)}
                                        components={{
                                            bold: <b></b>
                                        }}
                                    />
                                </Link>
                            </li>
                        );
                    }
                })}
            </ul>
        </div >
    )
}

export default FooterLinks
