import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';

import DetailsCard from './DetailsCard/DetailsCard';

const DetailsCards = props => {
    let detailsItems = Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]['content']).map((key, index) => {
        return (
            <DetailsCard
                key={index}
                number={index + 1}
                dashes={props.page + '/dashes.svg'}
                title={props.t(props.page + ':' + props.section + '.content.' + key + '.title')}
                text={props.t(props.page + ':' + props.section + '.content.' + key + '.text')}
            />
        )
    })

    return (
        <div className='container-custom DetailsCards'>
            <Row>
                {detailsItems}
            </Row>
        </div>
    )
}

export default withTranslation()(DetailsCards);