import { React } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

const FinancialProductCard = props => {
    return (
        <div className='card-container col-12 col-lg-4'>
            <div className='title-container'>
                <h3><Trans t={props.t} i18nKey={props.title} /></h3>
            </div>
            <div className='text-content d-flex flex-column align-items-center justify-content-center'>
                <p><Trans t={props.t} i18nKey={props.text} components={{ bold: <b></b> }} /></p>
                <Link to={props.t('routes:' + props.btnLink + '.path')} className="btn">{props.btn}</Link>
            </div>
        </div>
    )
}

export default withTranslation()(FinancialProductCard);