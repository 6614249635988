//JOBS
export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAIL = 'FETCH_JOBS_FAIL';

//COOKIES
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const REMOVE_COOKIES = 'REMOVE_COOKIES';
export const SET_BANNER_VISIBILITY = 'SET_BANNER_VISIBILITY';

//FORMS
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_ERROR = 'FORM_SUBMIT_ERROR';
export const RESET_SUBMIT = 'RESET_SUBMIT';

//structured Data
export const SET_JOB_STRUCTURED_DATA = 'SET_JOB_STRUCTURED_DATA';