import { React } from 'react';
import { Trans } from 'react-i18next';

const ItemsSection = props => {
    if (props.split) {
        return (
            <section className={props.noPadBottom ? 'ItemsSection-split section-padding noPadBottom ' : props.noPadBottomMobile ? 'ItemsSection-split section-padding noPadBottomMobile ' : 'ItemsSection-split section-padding '}>
                <div className='d-flex flex-column flex-xl-row container'>
                    <div className='banner flex-column align-items-center col-12 col-md-12 col-xl-4'>
                        <div className='title'>
                            <h2 className='text-uppercase'>{props.t('homepage:' + props.content + '.title1')}</h2>
                            <h2 className='text-uppercase'>{props.t('homepage:' + props.content + '.title2')}</h2>
                        </div>
                        <div className="text-box">
                            <div className="text">
                                <p className="m-0"> <Trans t={props.t} i18nKey={props.t('homepage:' + props.content + '.text')} /></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-xl-8'>
                        {props.children}
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={props.noPadBottom ? 'ItemsSection section-padding noPadBottom' : props.noPadBottomMobile ? 'ItemsSection section-padding noPadBottomMobile' : 'ItemsSection section-padding'}>
                <div className='container'>
                    <div className='banner d-flex flex-column justify-content-center align-items-center'>
                        <div className='title'>
                            <h2 className='text-uppercase mb-0'>{props.t('homepage:' + props.content + '.title')}</h2>
                        </div>
                        <div className="text-box">
                            <div className="text">
                                <p className="m-0">{props.t('homepage:' + props.content + '.text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {props.children}
                </div>
            </section>
        )
    }
}

export default ItemsSection;