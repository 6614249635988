import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionsTypes';

import {
    tryFetchJobsSaga
} from './jobs';

import {
    trySubmitForm
} from './forms';

export function* watchJobs() {
    yield all([
        takeEvery(actionTypes.FETCH_JOBS, tryFetchJobsSaga),
    ]);
}

export function* watchForm() {
    yield all([
        takeEvery(actionTypes.SUBMIT_FORM, trySubmitForm),
    ]);
}