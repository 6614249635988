import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import ProductBanner from '../../components/ProductBanner/ProductBanner';
import ProductSection from '../../components/ProductSection/ProductSection';
class Meeting extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="meeting" />
        <ProductBanner {...this.props} page="meeting" />
        <ProductSection noPadBottom {...this.props} page="meeting" section="features">
          <TextGroup {...this.props} page="meeting" section="features" />
        </ProductSection>
        <ProductSection {...this.props} page="meeting" section="advantages">
          <TextGroup {...this.props} page="meeting" section="advantages" />
          <TextGroup noPadBottom {...this.props} page="meeting" section="demo" />
        </ProductSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(Meeting);