import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Image, Row } from 'react-bootstrap';

import inovadora from "../../assets/images/homepage/certification/inovadora_2024.svg"
import PME23 from "../../assets/images/homepage/certification/PME_23.svg"
import excelencia from "../../assets/images/homepage/certification/excelencia_2022.svg"
import iso9001 from "../../assets/images/homepage/certification/iso9001.svg"
import iso27001 from "../../assets/images/homepage/certification/iso27001.svg"
import NP4457 from "../../assets/images/homepage/certification/NP4457.svg"
import DGERT from "../../assets/images/homepage/certification/DGERT.svg"
import Microsoft_Silver from "../../assets/images/homepage/certification/Microsoft_Silver.svg"

import iso9001_PDF from "../../assets/files/ISO_9001.pdf"
import iso27001_PDF from "../../assets/files/ISO_27001.pdf"
import NP4457_PDF from "../../assets/files/NP_4457.pdf"

const Certifications = props => {

    return (
        <section className='Certifications'>
            <Container className='container-custom'>
                <Row className='p-0'>
                    <Col xs={12} sm={11} md={12} className='d-flex align-items-center justify-content-start flex-column flex-lg-row p-0'>
                        <div className='p-0 w-100 d-flex justify-content-center align-items-center'>
                            <Col xs={4} md={3} lg={2}>
                                <Image src={inovadora} alt={props.t("alt:homepage.certifications.inovadora")} />
                            </Col>
                            <Col xs={2} sm md lg={1}>
                                <Image src={PME23} alt={props.t("alt:homepage.certifications.PME23")} />
                            </Col>
                            <Col xs={2} sm md lg={1}>
                                <Image src={excelencia} alt={props.t("alt:homepage.certifications.excelencia")} />
                            </Col>
                            <Col className='d-none d-md-block' md lg={1}>
                                <a target='_blank' rel="noreferrer" href={iso9001_PDF}>
                                    <Image src={iso9001} alt={props.t("alt:homepage.certifications.iso9001")} />
                                </a>
                            </Col>
                            <Col className='d-none d-md-block' md lg={1}>
                                <a target='_blank' rel="noreferrer" href={iso27001_PDF}>
                                    <Image src={iso27001} alt={props.t("alt:homepage.certifications.iso27001")} />
                                </a>
                            </Col>
                            <Col className='d-none d-md-block' md lg={1}>
                                <a target='_blank' rel="noreferrer" href={NP4457_PDF}>
                                    <Image src={NP4457} alt={props.t("alt:homepage.certifications.NP4457")} />
                                </a>
                            </Col>
                            <Col className='d-none d-lg-block' lg={1}>
                                <Image src={DGERT} alt={props.t("alt:homepage.certifications.dgert")} />
                            </Col>
                            <Col className='d-none d-lg-block' lg={1}>
                                <Image src={Microsoft_Silver} alt={props.t("alt:homepage.certifications.Microsoft")} />
                            </Col>
                        </div>
                        <div className='p-0 w-100 d-flex d-lg-none justify-content-center align-items-center second-row' >
                            <Col xs sm={2} className='d-md-none'>
                                <a target='_blank' rel="noreferrer" href={iso9001_PDF}>
                                    <Image src={iso9001} alt={props.t("alt:homepage.certifications.iso9001")} />
                                </a>
                            </Col>
                            <Col xs sm={2} className='d-md-none'>
                                <a target='_blank' rel="noreferrer" href={iso27001_PDF}>
                                    <Image src={iso27001} alt={props.t("alt:homepage.certifications.iso27001")} />
                                </a>
                            </Col>
                            <Col xs sm={2} className='d-md-none'>
                                <a target='_blank' rel="noreferrer" href={NP4457_PDF}>
                                    <Image src={NP4457} alt={props.t("alt:homepage.certifications.NP4457")} />
                                </a>
                            </Col>
                            <Col xs={3} md={3}>
                                <Image src={DGERT} alt={props.t("alt:homepage.certifications.dgert")} />
                            </Col>
                            <Col xs={3} md={3}>
                                <Image src={Microsoft_Silver} alt={props.t("alt:homepage.certifications.Microsoft")} />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default withTranslation()(Certifications);