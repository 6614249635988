import { React } from 'react';

import Auxiliary from '../../../hoc/Auxiliary';


const LanguageChanger = props => {
    return (
        <Auxiliary>
            <div className={props.menu ? "languageChange menu text-uppercase" : props.footer ? "languageChange footer text-uppercase d-flex" : "languageChange text-uppercase"}>
                <ul className={props.color === "black" ? "d-flex align-items-md-center black list-unstyled languages" : "d-flex align-items-md-center list-unstyled languages"}>
                    {props.currentLang !== 'pt' ? <li onClick={() => { props.onChangeLanguage('pt') }} className={["d-flex", props.currentLang === 'pt' ? 'd-none' : ""].join(" ")}>
                        <span className="icon-country">pt</span>
                    </li> : ""}
                    {props.currentLang !== 'en' ? <li onClick={() => { props.onChangeLanguage('en') }} className={["d-flex", props.currentLang === 'en' ? 'd-none' : ""].join(" ")}>
                        <span className="icon-country">en</span>
                    </li> : ""}
                    {props.currentLang !== 'fr' ? <li onClick={() => { props.onChangeLanguage('fr') }} className={["d-flex", props.currentLang === 'fr' ? 'd-none' : ""].join(" ")}>
                        <span className="icon-country">fr</span>
                    </li> : ""}
                </ul>
            </div>
        </Auxiliary >
    )
}

export default LanguageChanger;