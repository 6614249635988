import { React, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import background from "../../assets/images/homepage/opportunities/bg_blue_opportunities.png"

const Opportunities = props => {
    const [displayJobsList, setDisplayJobsList] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [error, seterror] = useState(null)

    useEffect(() => {
        setJobsToDisplay(props.jobs)
    }, [props.jobs])

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }

    }, [props.error])

    const setJobsToDisplay = (jobs) => {
        if (jobs) {
            seterror(false)
            setisLoading(false)
            let final = Object.keys(jobs).slice(0, 6).map((job) => {
                if (jobs[job]["jobTitle"]) {
                    return (
                        <Col key={job} md={6}>
                            <div className='link-container'>
                                <Link className='link' to={props.t('routes:jobs.path') + '/' + jobs[job]["id"]}>
                                    <p>{jobs[job]["jobTitle"]}</p>
                                    <FontAwesomeIcon className='icon' icon={faArrowRight} />
                                </Link>
                            </div>
                        </Col >
                    )
                }
                else return false

            })
            setDisplayJobsList(final)
        }
    }

    return (
        <section className='Opportunities section-padding' style={background ? { backgroundImage: "url(" + background + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top" } : {}}>
            <div className='container position-relative'>
                <Row>
                    <div className='title'>
                        <h2 className='col-12 col-sm-8 col-md-6 col-lg-5 text-uppercase'>{props.t('homepage:opportunities.title1')}</h2>
                        <h2 className='col-12 col-sm-8 col-md-6 col-lg-5 text-uppercase'>{props.t('homepage:opportunities.title2')}</h2>
                    </div>
                </Row>
                <Row>
                    {error ?
                        <div className='mt-3 d-flex justify-content-center align-items-center'>
                            <BarLoader />
                        </div>
                        : isLoading ?
                            <div className='mt-3 d-flex justify-content-center align-items-center'>
                                <BarLoader />
                            </div>
                            : displayJobsList && displayJobsList.length <= 0 ? <p className='noOffer font-weight-700'>{props.t('homepage:opportunities.error')}</p> : displayJobsList
                    }
                    <div className='more-btn'>
                        <Link className='btn' to={props.t("routes:jobs.path")}>{props.t('homepage:opportunities.btn')}</Link>
                    </div>
                </Row>
            </div>
        </section>
    )

}
const mapStateProps = state => {
    return ({
        jobs: state.jobs.jobs,
        error: state.jobs.error
    });
}
export default withTranslation()(connect(mapStateProps)(Opportunities));