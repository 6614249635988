import { React, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Image, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/cookies';

import compete2020 from "../../assets/images/modals/logo-compete2020-white.svg"
import projectImage from "../../assets/images/modals/ficha-projeto-imagens.png"

const Modals = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    switch (props.type) {
        case "ims":
            return (
                <div className='Modals'>
                    <p className="link-modal" onClick={() => handleShow()}>
                        <Trans
                            t={props.t}
                            i18nKey={props.btn}
                            components={{
                                bold: <b> </b>
                            }}
                        />
                    </p>
                    <Modal className='modal-info' fullscreen={"lg-down"} show={show} onHide={handleClose} centered size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title><h3>{props.t("modals:ims.title")}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-0 d-flex align-items-center justify-content-center'>
                            <div className='modal-content'>
                                <p>
                                    <Trans t={props.t} i18nKey={props.t('modals:ims.text')}
                                        components={{
                                            dot: <span className='dot'> </span>

                                        }}
                                    />
                                </p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        case "project":
            return (
                <div className='Modals'>
                    <p className="link-modal" onClick={() => handleShow()}>
                        <Trans
                            t={props.t}
                            i18nKey={props.btn}
                            components={{
                                bold: <b> </b>
                            }}
                        />
                    </p>
                    <Modal className='modal-info' fullscreen={"lg-down"} show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title><h3>{props.t("modals:project.project1.title")}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-0 d-flex align-items-center justify-content-center'>
                            <div className='modal-content'>
                                <Col className='image-container' sm={8} md={5}>
                                    <Image fluid src={compete2020} />
                                </Col>
                                <p>
                                    <Trans t={props.t} i18nKey={props.t('modals:project.project1.text')}
                                        components={{
                                            b: <b> </b>

                                        }}
                                    />
                                </p>
                                <Col className='image-container' >
                                    <Image fluid src={projectImage} />
                                </Col>
                                <div className='title'>
                                    <h3>{props.t("modals:project.project2.title")}</h3>
                                </div>
                                <Col className='image-container' sm={8} md={5}>
                                    <Image fluid src={compete2020} />
                                </Col>
                                <p>
                                    <Trans t={props.t} i18nKey={props.t('modals:project.project2.text')}
                                        components={{
                                            b: <b> </b>

                                        }}
                                    />
                                </p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        case "privacy":
            return (
                <div className='Modals'>
                    <p className="link-modal" onClick={() => handleShow()}>
                        <Trans
                            t={props.t}
                            i18nKey={props.btn}
                            components={{
                                bold: <b> </b>
                            }}
                        />
                    </p>
                    <Modal className='modal-info' fullscreen={"lg-down"} show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title><h3>{props.t("modals:privacy.title")}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-0 d-flex align-items-center justify-content-center'>
                            <div className='modal-content'>
                                <p>
                                    <Trans t={props.t} i18nKey={props.t('modals:privacy.text')}
                                        components={{
                                            h4: <h4> </h4>,
                                            u: <u> </u>,
                                            LinkDPO: <Link to="mailto:dpo@m-profiler.com"> </Link>,
                                            LinkHomepage: <Link to={props.t("routes:homepage.path")}> </Link>
                                        }}
                                    />
                                </p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        case "cookies":
            return (
                <div className='Modals'>
                    <p className="link-modal" onClick={() => handleShow()}>
                        <Trans
                            t={props.t}
                            i18nKey={props.btn}
                            components={{
                                bold: <b> </b>
                            }}
                        />
                    </p>
                    <Modal className='modal-info' fullscreen={"lg-down"} show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title><h3>{props.t("modals:cookies.title")}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-0 d-flex align-items-center justify-content-center'>
                            <div className='modal-content'>
                                <p>
                                    <Trans t={props.t} i18nKey={props.t('modals:cookies.text')}
                                        components={{
                                            h4: <h4> </h4>,
                                            u: <u> </u>,
                                            LinkDPO: <Link to="mailto:dpo@m-profiler.com"> </Link>,
                                            LinkHomepage: <Link to={props.t("routes:homepage.path")}> </Link>
                                        }}
                                    />
                                </p>
                                <a className='cookie-btn' onClick={() => props.onSetBannervisibility("show")}>{props.t("modals:cookies.btn")}</a>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        default:
            return <></>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetBannervisibility: (value) => dispatch(actions.setBannerVisibility(value))
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(Modals));