import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ProductSection from '../../components/ProductSection/ProductSection';
import CTA from '../../components/CTA/CTA';
class Software extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="web" />
        <ServiceBanner product {...this.props} service page="web" />
        <ProductSection yellowBar noPadBottom noPadTitle {...this.props} page="web" section="info">
          <TextGroup noPadBottom {...this.props} page="web" section="info" />
        </ProductSection>
        <ProductSection yellowBar noPadBottom {...this.props} page="web" section="types">
          <TextGroup {...this.props} page="web" section="types" />
        </ProductSection>
        <ProductSection yellowBar noPadBottom {...this.props} page="web" section="benefits">
          <TextGroup {...this.props} page="web" section="benefits" />
        </ProductSection>
        <ProductSection noTitle noPadTitle noImg yellowBar  {...this.props} page="web" >
          <CTA noBG {...this.props} />
        </ProductSection>

      </Auxiliary>
    )
  }
}

export default withTranslation()(Software);