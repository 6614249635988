import { React } from 'react';
import { withTranslation } from 'react-i18next';

import { Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ServiceCard = props => {
    let iconSrc = props.icon ? require('../../../assets/icons/homepage/' + props.icon) : "";
    let iconDashes = props.dashes ? require('../../../assets/icons/homepage/' + props.dashes) : "";
    return (
        <Col md={6} xl={4} className='card-container'>
            <div className="card-content bdr">
                <div className="bdr-right"></div>
                <div className="icon">
                    <Image src={iconSrc} alt={props.t("alt:homepage.services." + props.id)} />
                </div>
                <div className="text-container">
                    <h3 className="text-uppercase">{props.title}</h3>
                    <p>{props.text}</p>
                </div>
            </div>
            <div className="bottom-box">
                <Link rel="noreferrer" target={props.btnLink === "nearshoring" ? "_blank" : "_self"} to={props.btnLink === "nearshoring" ? "https://www.nearshorit.com/" : props.t('routes:' + props.btnLink + '.path')} className="btn">{props.btn}</Link>
                <div className="dashes dashes-bottom">
                    <Image src={iconDashes} />
                </div>
            </div>
            <div className="bdr-bottom"></div>
        </Col>
    )
}

export default withTranslation()(ServiceCard);