import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';


const initialSatate = {
    error: null,
    submited: null,
    link_bucket: null
}

const forms = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.FORM_SUBMIT_ERROR:
            return updateObject(state, { error: true, submited: false });
        case actionTypes.FORM_SUBMIT_SUCCESS:
            return updateObject(state, { error: false, submited: true });
        case actionTypes.RESET_SUBMIT:
            return updateObject(state, { error: null, submited: null });
        default:
            return state;
    }
};

export default forms;