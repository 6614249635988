import { Children, React } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Col, Image, Row } from 'react-bootstrap';

import compete2020 from "../../assets/images/logos/compete2020.png"
const TextGroup = props => {
    let items = Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"]).map((key) => {
        return (
            Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key]).map((type, index) => {
                switch (type) {
                    case "title":
                        return <div key={index} className={props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.half') ? 'title-container half' : "title-container"}>
                            <div className='container-custom title p-0'>
                                <h3 className='d-flex justify-content-between justify-content-md-start'>
                                    {props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}{props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') && props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') ? <Link to={props.t("routes:" + key + ".path")}>
                                        <div><FontAwesomeIcon className='icon' icon={faAngleRight} /></div>
                                    </Link> : ""}</h3>
                            </div>
                        </div>
                    case "soloTitle":
                        return <div key={index} className={props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.half') ? 'title-container solo half' : "title-container solo"}>
                            <div className='container-custom title p-0'>
                                <h3 className='d-flex justify-content-between justify-content-md-start'>
                                    {props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}{props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') && props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') ? <Link to={props.t("routes:" + key + ".path")}>
                                        <div><FontAwesomeIcon className='icon' icon={faAngleRight} /></div>
                                    </Link> : ""}</h3>
                            </div>
                        </div>
                    case "subtitle":
                        return <div className='container-custom subtitle'>
                            <h4 className='title d-flex justify-content-between justify-content-md-start'>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.title')}{props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') && props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') ? <Link to={props.t("routes:" + key + ".path")}>
                                <div><FontAwesomeIcon className='icon' icon={faAngleRight} /></div>
                            </Link> : ""}</h4>
                            <div className='text-container'>
                                <p className={props.noPadBottom || props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.noPadBottom') ? 'noPadBottom' : props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.default') ? 'default' : ''}>
                                    <Trans
                                        t={props.t}
                                        i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                        components={{
                                            LinkItSupport: <a href={props.t('routes:it_support.path')} rel="noreferrer" target='_blank'> </a>,
                                            LinkWeb: <a href={props.t('routes:web.path')} rel="noreferrer" target='_blank'> </a>,
                                            LinkOTM: <a href={props.t('routes:otm.path')} rel="noreferrer" target='_blank'> </a>,
                                            em: <i></i>,
                                            LinkIGAI: <a href="https://www.igai.pt/pt/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                                            LinkIGAIEN: <a href="https://www.igai.pt/en/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                                            LinkIGF: <a href="https://www.igf.gov.pt/" rel="noreferrer" target='_blank'> </a>
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    case "subtitleDescription":
                        return <div className='container-custom subtitle description'>
                            <h4 className='title d-flex justify-content-between justify-content-md-start'>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.title')}{props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') && props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.btn') ? <Link to={props.t("routes:" + key + ".path")}>
                                <div><FontAwesomeIcon className='icon' icon={faAngleRight} /></div>
                            </Link> : ""}</h4>
                            <div className='text-container'>
                                <p className={props.noPadBottom || props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.noPadBottom') ? 'noPadBottom' : props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.' + type + '.default') ? 'default' : ''}>
                                    <Trans
                                        t={props.t}
                                        i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                        components={{
                                            LinkItSupport: <a href={props.t('routes:it_support.path')} rel="noreferrer" target='_blank'> </a>,
                                            LinkWeb: <a href={props.t('routes:web.path')} rel="noreferrer" target='_blank'> </a>,
                                            LinkOTM: <a href={props.t('routes:otm.path')} rel="noreferrer" target='_blank'> </a>,
                                            em: <i></i>,
                                            LinkIGAI: <a href="https://www.igai.pt/pt/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                                            LinkIGAIEN: <a href="https://www.igai.pt/en/Pages/default.aspx" rel="noreferrer" target='_blank'> </a>,
                                            LinkIGF: <a href="https://www.igf.gov.pt/" rel="noreferrer" target='_blank'> </a>
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    case "text":
                        return <div key={index} className='container-custom text-container'>
                            <p className={props.noPadBottom || props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.noPadBottom') ? 'noPadBottom' : props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.default') ? 'default' : ''}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type)}
                                    components={{
                                        LinkItSupport: <a href={props.t('routes:it_support.path')} rel="noreferrer" target='_blank'> </a>,
                                        LinkWeb: <a href={props.t('routes:web.path')} rel="noreferrer" target='_blank'> </a>,
                                        LinkOTM: <a href={props.t('routes:otm.path')} rel="noreferrer" target='_blank'> </a>,
                                        em: <i></i>
                                    }}
                                />
                            </p>
                        </div>
                    case "list":
                        return (
                            <div key={index} className='container-custom text-container'>
                                <ul className='list'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <li key={listItems}>
                                            <Trans
                                                t={props.t}
                                                i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems)}
                                                components={{
                                                    h3: <h3 className='h3-text m-0'>{Children}</h3>,
                                                    em: <i></i>,
                                                    bold: <b></b>
                                                }}
                                            />
                                        </li>
                                    })}
                                </ul>
                            </div>
                        )
                    case "listOnly":
                        return (
                            <div key={index} className='container-custom text-container'>
                                <ul className='list only'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <li key={listItems}>
                                            <Trans
                                                t={props.t}
                                                i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems)}
                                                components={{
                                                    h3: <h3 className='h3-text m-0'>{Children}</h3>,
                                                    em: <i></i>,
                                                    bold: <b></b>
                                                }}
                                            />
                                        </li>
                                    })}
                                </ul>
                            </div>
                        )
                    case "linkList":
                        return (
                            <div key={index} className='container-custom text-container'>
                                <ul className='list links'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <li key={listItems}>
                                            <Link to={props.t("routes:" + listItems + ".path")}>
                                                {props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems + '.text')}
                                                <FontAwesomeIcon className='icon' icon={faAngleRight} />
                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        )
                    case "descriptionList":
                        return (
                            <div key={index} className='container-custom text-container'>
                                <ul className='list description'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <div key={listItems} className='content-items'>
                                            <h3>
                                                <li>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems + '.title')}</li>
                                            </h3>
                                            <p>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems + '.text')}</p>
                                        </div>
                                    })}
                                </ul>
                            </div>
                        )
                    case "certificates":
                        return (
                            <div key={index} className='container-custom text-container'>
                                <ul className='list description'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <div key={listItems} className='content-items'>
                                            <h3>
                                                <li>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems + '.title')}</li>
                                            </h3>
                                            <p>{props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems + '.text')}</p>
                                        </div>
                                    })}
                                </ul>
                            </div>
                        )
                    case "btn":
                        return <div key={index} className={props.noBtnPadding ? 'container-custom text-container padding py-0' : 'container-custom text-container padding'}>
                            <Link className={props.small ? 'btn small' : 'btn'} to={props.t('routes:' + props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.to') + '.path')}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                />
                            </Link>
                        </div>
                    case "pdfBtn":
                        let pdf = props.page ? require('../../assets/files/' + props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.pdfName') + "-" + props.i18n.language + '.pdf') : null;
                        return <div key={index} className={props.noBtnPadding ? 'container-custom text-container padding py-0' : 'container-custom text-container padding'}>
                            <a className={props.small ? 'btn small' : 'btn'} rel="noreferrer" target='_blank' href={pdf}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                />
                            </a>
                        </div>
                    case "solidBtn":
                        return <div key={index} className={props.noBtnPadding ? 'container-custom text-container padding py-0' : 'container-custom text-container padding py-0'}>
                            <Link className='solid-btn' to={props.t('routes:' + props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.to') + '.path')}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                />
                            </Link>
                        </div>
                    case "quote":
                        return <div key={index} className='container-custom text-container padding py-0'>
                            <blockquote>
                                <div className='quote-text'>
                                    <p className=' text'>
                                        <Trans
                                            t={props.t}
                                            i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                        />
                                        <br />
                                    </p>
                                    <br />
                                    <p className=' p-0 author'>
                                        <Trans
                                            t={props.t}
                                            i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.author')}
                                        />
                                        <br />
                                    </p>
                                    <p className=' p-0 role'>
                                        <Trans
                                            t={props.t}
                                            i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.role')}
                                        />
                                        <br />
                                    </p>
                                </div>


                            </blockquote>
                        </div>
                    case "demo":
                        return <div key={index} className={props.noPadBottom ? 'container-custom text-container padding noPadBottom' : 'container-custom text-container padding'}>
                            <Link className='btn demo' to={props.t('routes:' + props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.to') + '.path') + "?product=" + props.t(props.page + ":" + props.section + ".content." + key + '.' + type + '.product')}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                />
                            </Link>
                        </div>
                    case "customDemo":
                        return <div key={index} className={props.noPadBottom ? 'container-custom text-container padding noPadBottom' : 'container-custom text-container padding'}>
                            <Row>
                                <Col>
                                    <Link className='btn demo custom-btn' to={props.t('routes:' + props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.to') + '.path') + "?product=" + props.t(props.page + ":" + props.section + ".content." + key + '.' + type + '.product')}>
                                        <Trans
                                            t={props.t}
                                            i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.text')}
                                        />
                                    </Link>
                                </Col>
                                <Col md={8} lg={6}>
                                    <Image fluid src={compete2020} />
                                </Col>
                            </Row>
                        </div>
                    case "listBG":
                        return (
                            <div key={index} className='container text-container'>
                                <ul className='listBG'>
                                    {Object.keys(props.i18n.getResource(props.i18n.language, props.page)[props.section]["content"][key][type]).map((listItems) => {
                                        return <li key={listItems}>
                                            <Trans
                                                t={props.t}
                                                i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type + '.' + listItems)}
                                                components={{
                                                    h3: <h3 className='h3-text m-0'>{Children}</h3>,
                                                    em: <i></i>,
                                                    bold: <b></b>
                                                }}
                                            />
                                        </li>
                                    })}
                                </ul>
                            </div>
                        )
                    case "textNoContainer":
                        return <div key={index} className='container text-container px-0'>
                            <p className={props.i18n.exists(props.page + ':' + props.section + '.content.' + key + '.padBottom') ? 'px-0' : 'p-0'}>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t(props.page + ':' + props.section + '.content.' + key + '.' + type)}
                                    components={{
                                        LinkItSupport: <a href={props.t('routes:it_support.path')} rel="noreferrer" target='_blank'> </a>,
                                        em: <i></i>
                                    }}
                                />
                            </p>
                        </div>
                    default: return <></>
                }
            })
        )
    })

    return (
        <div className='TextGroup'>
            {items}
        </div>
    )
}

export default withTranslation()(TextGroup);