import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import ProductBanner from '../../components/ProductBanner/ProductBanner';
import ProductSection from '../../components/ProductSection/ProductSection';

class Commercial extends Component {
    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="commercial" />
                <ProductBanner imgPadding {...this.props} page="commercial" />
                <ProductSection {...this.props} facilities page="commercial" section="features">
                    <TextGroup {...this.props} page="commercial" section="features" />
                    <TextGroup noPadBottom {...this.props} page="commercial" section="demo" />
                </ProductSection>
            </Auxiliary>
        )
    }
}

export default withTranslation()(Commercial);