import { React } from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { Link } from 'react-router-dom';

import bgThankYou from '../../assets/images/thankYou/bg_thankYou.png';
import like from '../../assets/icons/thankYou/like_bg.svg';
import corner from '../../assets/icons/thankYou/corner.svg';
import dashes from '../../assets/icons/thankYou/dashes.svg';


const ThankYouPage = props => {
    return (
        <section style={{ backgroundImage: "url(" + bgThankYou + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} className='ThankYouPage'>
            <div className='content section-container'>
                <div className='floating-block'>
                    <diV className="corner d-none d-md-block">
                        <Image fluid src={corner} />
                    </diV>
                    <div className="floating-block-content">
                        <Image fluid className='like-icon' src={like} />
                        <h1 className='title'>{props.t("thankYou:title")}</h1>
                        <p className='text'>{props.t("thankYou:text")}</p>
                        <p className='socials-text'>{props.t("thankYou:socials")}</p>
                        <div className='socials'>
                            <a className="icon-link" href="https://www.linkedin.com/company/1408132/" target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <FontAwesomeIcon className='icon' icon={faLinkedinIn} />
                                </div>
                            </a>
                            <a className="icon-link" href="https://www.instagram.com/match_profiler/" target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <FontAwesomeIcon className='icon' icon={faInstagram} />
                                </div>
                            </a>
                            <a className="icon-link" href="https://www.facebook.com/matchprofiler/" target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <FontAwesomeIcon className='icon' icon={faFacebookF} />
                                </div>
                            </a>
                            <a className="icon-link" href="https://www.youtube.com/channel/UCa6UieLcdzC3tfwelN7je3g" target="_blank" rel="noreferrer">
                                <div className="icon-container">
                                    <FontAwesomeIcon className='icon' icon={faYoutube} />
                                </div>
                            </a>
                        </div>
                    </div>
                    <diV className="dashes d-none d-md-block">
                        <Image fluid src={dashes} />
                    </diV>
                </div>
                <Link className="btn" to={props.t("routes:homepage.path")}>{props.t("thankYou:btn")}</Link>
            </div>
        </section>
    )
}

export default ThankYouPage;