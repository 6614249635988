import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import languageDetectorOptions from "./languageDetectorOptions";
import { initReactI18next } from "react-i18next";

//imports of translation files
import routesEN from "./assets/locales/en/routes.json";
import routesPT from "./assets/locales/pt/routes.json";
import routesFR from "./assets/locales/fr/routes.json";
import oldRoutesEN from "./assets/locales/en/OldRoutes.json";
import oldRoutesPT from "./assets/locales/pt/OldRoutes.json";
import oldRoutesFR from "./assets/locales/fr/OldRoutes.json";
import homepageEN from "./assets/locales/en/homepage.json";
import homepagePT from "./assets/locales/pt/homepage.json";
import homepageFR from "./assets/locales/fr/homepage.json";
import contactsEN from "./assets/locales/en/contacts.json";
import contactsPT from "./assets/locales/pt/contacts.json";
import contactsFR from "./assets/locales/fr/contacts.json";
import metatagsEN from "./assets/locales/en/metatags.json";
import metatagsPT from "./assets/locales/pt/metatags.json";
import metatagsFR from "./assets/locales/fr/metatags.json";

//Services
import consultancyPt from "./assets/locales/pt/services/consultancy.json";
import consultancyEn from "./assets/locales/en/services/consultancy.json";
import consultancyFr from "./assets/locales/fr/services/consultancy.json";
import companyCertificationPt from "./assets/locales/pt/services/companyCertification.json";
import companyCertificationEn from "./assets/locales/en/services/companyCertification.json";
import companyCertificationFr from "./assets/locales/fr/services/companyCertification.json";
import embeddedRecruitmentPt from "./assets/locales/pt/services/embeddedRecruitment.json";
import embeddedRecruitmentEn from "./assets/locales/en/services/embeddedRecruitment.json";
import embeddedRecruitmentFr from "./assets/locales/fr/services/embeddedRecruitment.json";
import gdprPt from "./assets/locales/pt/services/gdpr.json";
import gdprEn from "./assets/locales/en/services/gdpr.json";
import gdprFr from "./assets/locales/fr/services/gdpr.json";
import rgpcPt from "./assets/locales/pt/services/rgpc.json";
import rgpcEn from "./assets/locales/en/services/rgpc.json";
import rgpcFr from "./assets/locales/fr/services/rgpc.json";
import outsourcingPt from "./assets/locales/pt/services/outsourcing.json";
import outsourcingEn from "./assets/locales/en/services/outsourcing.json";
import outsourcingFr from "./assets/locales/fr/services/outsourcing.json";
import supportPt from "./assets/locales/pt/services/support.json";
import supportEn from "./assets/locales/en/services/support.json";
import supportFr from "./assets/locales/fr/services/support.json";
import ctaPt from "./assets/locales/pt/cta.json";
import ctaEn from "./assets/locales/en/cta.json";
import ctaFr from "./assets/locales/fr/cta.json";

//Products
import carsharePt from "./assets/locales/pt/products/carshare.json";
import carshareEn from "./assets/locales/en/products/carshare.json";
import carshareFr from "./assets/locales/fr/products/carshare.json";
import perceptumPt from "./assets/locales/pt/products/perceptum.json";
import perceptumEn from "./assets/locales/en/products/perceptum.json";
import perceptumFr from "./assets/locales/fr/products/perceptum.json";
import otmPt from "./assets/locales/pt/products/otm.json";
import otmEn from "./assets/locales/en/products/otm.json";
import otmFr from "./assets/locales/fr/products/otm.json";
import meetingPt from "./assets/locales/pt/products/meeting.json";
import meetingEn from "./assets/locales/en/products/meeting.json";
import meetingFr from "./assets/locales/fr/products/meeting.json";
import consiliumPt from "./assets/locales/pt/products/consilium.json";
import consiliumEn from "./assets/locales/en/products/consilium.json";
import consiliumFr from "./assets/locales/fr/products/consilium.json";
import smeapPt from "./assets/locales/pt/products/smeap.json";
import smeapEn from "./assets/locales/en/products/smeap.json";
import smeapFr from "./assets/locales/fr/products/smeap.json";
import webPt from "./assets/locales/pt/products/web.json";
import webEn from "./assets/locales/en/products/web.json";
import webFr from "./assets/locales/fr/products/web.json";
import softwarePt from "./assets/locales/pt/products/software.json";
import softwareEn from "./assets/locales/en/products/software.json";
import softwareFr from "./assets/locales/fr/products/software.json";
import mobilePt from "./assets/locales/pt/products/mobile.json";
import mobileEn from "./assets/locales/en/products/mobile.json";
import mobileFr from "./assets/locales/fr/products/mobile.json";

//Finantials
import facilitiesPt from "./assets/locales/pt/finantials/facilities.json";
import facilitiesEn from "./assets/locales/en/finantials/facilities.json";
import facilitiesFr from "./assets/locales/fr/finantials/facilities.json";
import commercialPt from "./assets/locales/pt/finantials/commercial.json";
import commercialEn from "./assets/locales/en/finantials/commercial.json";
import commercialFr from "./assets/locales/fr/finantials/commercial.json";
import billingPt from "./assets/locales/pt/finantials/billing.json";
import billingEn from "./assets/locales/en/finantials/billing.json";
import billingFr from "./assets/locales/fr/finantials/billing.json";
import parcPt from "./assets/locales/pt/finantials/parc.json";
import parcEn from "./assets/locales/en/finantials/parc.json";
import parcFr from "./assets/locales/fr/finantials/parc.json";

// career
import jobsPt from "./assets/locales/pt/career/jobs.json";
import jobsEn from "./assets/locales/en/career/jobs.json";
import jobsFr from "./assets/locales/fr/career/jobs.json";
import jobPt from "./assets/locales/pt/career/job.json";
import jobEn from "./assets/locales/en/career/job.json";
import jobFr from "./assets/locales/fr/career/job.json";

//Partials
import partialsEn from "./assets/locales/en/partials.json";
import partialsPt from "./assets/locales/pt/partials.json";
import partialsFr from "./assets/locales/fr/partials.json";

//Modals
import modalsEn from "./assets/locales/en/modals.json"
import modalsPt from "./assets/locales/pt/modals.json"
import modalsFr from "./assets/locales/fr/modals.json"

//404
import page404En from "./assets/locales/en/404.json"
import page404Pt from "./assets/locales/pt/404.json"
import page404Fr from "./assets/locales/fr/404.json"

//Thank You Page
import thankYouEn from "./assets/locales/en/thankYou.json"
import thankYouPt from "./assets/locales/pt/thankYou.json"
import thankYouFr from "./assets/locales/fr/thankYou.json"

//Alt text for images
import altEn from "./assets/locales/en/alt.json"
import altPt from "./assets/locales/pt/alt.json"
import altFr from "./assets/locales/fr/alt.json"

//associate the translations with locales
const resources = {
  en: {
    routes: routesEN,
    oldRoutes: oldRoutesEN,
    homepage: homepageEN,
    contacts: contactsEN,
    metatags: metatagsEN,
    partials: partialsEn,
    consultancy: consultancyEn,
    companyCertification: companyCertificationEn,
    embeddedRecruitment: embeddedRecruitmentEn,
    gdpr: gdprEn,
    rgpc: rgpcEn,
    outsourcing: outsourcingEn,
    support: supportEn,
    cta: ctaEn,
    carshare: carshareEn,
    perceptum: perceptumEn,
    otm: otmEn,
    meeting: meetingEn,
    consilium: consiliumEn,
    smeap: smeapEn,
    web: webEn,
    software: softwareEn,
    mobile: mobileEn,
    facilities: facilitiesEn,
    commercial: commercialEn,
    billing: billingEn,
    parc: parcEn,
    jobs: jobsEn,
    job: jobEn,
    modals: modalsEn,
    page404: page404En,
    thankYou: thankYouEn,
    alt: altEn
  },
  pt: {
    routes: routesPT,
    oldRoutes: oldRoutesPT,
    homepage: homepagePT,
    contacts: contactsPT,
    metatags: metatagsPT,
    partials: partialsPt,
    consultancy: consultancyPt,
    companyCertification: companyCertificationPt,
    embeddedRecruitment: embeddedRecruitmentPt,
    gdpr: gdprPt,
    rgpc: rgpcPt,
    outsourcing: outsourcingPt,
    support: supportPt,
    cta: ctaPt,
    carshare: carsharePt,
    perceptum: perceptumPt,
    otm: otmPt,
    meeting: meetingPt,
    consilium: consiliumPt,
    smeap: smeapPt,
    web: webPt,
    software: softwarePt,
    mobile: mobilePt,
    facilities: facilitiesPt,
    commercial: commercialPt,
    billing: billingPt,
    parc: parcPt,
    jobs: jobsPt,
    job: jobPt,
    modals: modalsPt,
    page404: page404Pt,
    thankYou: thankYouPt,
    alt: altPt
  },
  fr: {
    routes: routesFR,
    oldRoutes: oldRoutesFR,
    homepage: homepageFR,
    contacts: contactsFR,
    metatags: metatagsFR,
    partials: partialsFr,
    consultancy: consultancyFr,
    companyCertification: companyCertificationFr,
    embeddedRecruitment: embeddedRecruitmentFr,
    gdpr: gdprFr,
    rgpc: rgpcFr,
    outsourcing: outsourcingFr,
    support: supportFr,
    cta: ctaFr,
    carshare: carshareFr,
    perceptum: perceptumFr,
    otm: otmFr,
    meeting: meetingFr,
    consilium: consiliumFr,
    smeap: smeapFr,
    web: webFr,
    software: softwareFr,
    mobile: mobileFr,
    facilities: facilitiesFr,
    commercial: commercialFr,
    billing: billingFr,
    parc: parcFr,
    jobs: jobsFr,
    job: jobFr,
    modals: modalsFr,
    page404: page404Fr,
    thankYou: thankYouFr,
    alt: altFr
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: languageDetectorOptions,
    fallbackLng: ["pt", "en", "fr"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "a"], // don't convert to <1></1> if simple react elements
  });

export default i18n;
