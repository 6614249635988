import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {

    const location = useLocation();

    const [pageID, setPageID] = useState(window.history.state.idx)

    useEffect(() => {
        const element = document.getElementById("spontaneous");
        if (window.location.href.includes("#spontaneous")) {
            window.scrollTo(0, element.offsetTop);
        } else {
            //window.scrollTo(0, 0);
            if (window.history.state.idx > pageID) {
                window.scrollTo(0, 0);
                setPageID(window.history.state.idx)
            } else {
                window.history.scrollRestoration = "auto"
                setPageID(pageID - 1)
            }
        }
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;