import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ContactBanner from '../../components/ContactBanner/ContactBanner';
import ContactInfo from '../../components/ContactInfo/ContactInfo';

class Contacts extends Component {
    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="contacts" />
                <ContactBanner {...this.props} />
                <ContactInfo {...this.props} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Contacts)