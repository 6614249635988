import { React, Suspense, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

const Head = props => {
    const linkServer = 'https://www.m-profiler.com/';
    // const linkServer = 'http://mp-qual.s3-website-eu-west-1.amazonaws.com/';
    const { id } = props;
    const currentLink = navigator.userAgent !== 'ReactSnap' ? window.location.href : (linkServer + window.location.pathname);

    //shared image
    const imageName = id + '.image';
    //folder where to look for the share
    const lang = props.i18n.language;
    const langFolder = lang === "pt" ? "pt" : "en";
    const img = props.i18n.exists('metatags:' + id + '.image', langFolder) ? require("../../../assets/images/shared/" + langFolder + props.i18n.getResource(langFolder, 'metatags', imageName)) : null;

    //Structured Data
    const [displayStructuredData, setDisplayStructuredData] = useState(props.structuredData);

    useEffect(() => {
        setDisplayStructuredData(props.structuredData)
    }, [props.structuredData]);

    useEffect(() => {
        {/* Uncomment for prod version*/ }
        if (props.cookies) {
            const head = document.querySelector("head");
            const script = document.createElement("script");
            script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=GTM-KQNQQWD");
            head.appendChild(script);

            const script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag() { window.dataLayer.push(arguments); }gtag('js', new Date()); gtag('config', 'GTM-KQNQQWD');";
            head.appendChild(script2);
        }
    }, [props.cookies])

    let imgLink = null;

    if (img)
        imgLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + img : linkServer + img;

    return (
        <Suspense>
            <Helmet titleTemplate="%s | Match Profiler" defaultTitle="Match Profiler">
                <html lang={lang} amp />
                <meta name="title" property="og:title" content={props.i18n.getResource(lang, 'metatags', id + '.title')} />
                {/* <meta name="description" content={props.i18n.getResource(langFolder, 'metatags', id + '.description')} /> */}
                <title>{props.i18n.getResource(lang, 'metatags', id + '.title')}</title>
                <meta property="og:url" content={currentLink} />
                <meta property="og:type" content="WebPage" />
                <meta property="og:site_name" content="Match Profiler" />
                <meta name="description" content={props.i18n.getResource(props.i18n.language, 'metatags', id + '.description')} />
                {imgLink ? (<meta name="image" property="og:image" content={imgLink} />) : null}
                {id === "offer" ? <link rel="canonical" href='https://www.m-profiler.com/carreiras' /> : ""}
                <meta name="google-site-verification" content="nUu1rI19E8WDV9bmbIoopGlrbTtx6RZaLQ9X8JRy-PY" />
                {/* Uncomment for prod version*/}
                {displayStructuredData && <script type="application/ld+json">{displayStructuredData}</script>}
            </Helmet>
        </Suspense>
    )
}

const mapStateProps = state => {
    return {
        structuredData: state.jobs.jobStructuredData,
        cookies: state.cookies.accepted_cookies,
    }
}

export default connect(mapStateProps)(Head);