
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ThankYouPage from '../../components/ThankYouPage/ThankYouPage';
import Page404 from '../Page404/Page404';

class ThankYou extends Component {
    render() {
        let headId = '';
        let renderNew = <Page404 />
        if (window.location.pathname.split('/')[2] === this.props.t("routes:thank_you.id")) {
            headId = "thankYou";
            renderNew = <ThankYouPage {...this.props} />;
        }
        return (
            <Auxiliary>
                <Head {...this.props} id={headId} />
                {renderNew}
            </Auxiliary>
        )
    }
}

export default withTranslation()(ThankYou)