import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

import Member from './Member/Member';

const Team = props => {
    let members = Object.keys(props.i18n.getResource(props.i18n.language, 'homepage')['team']['members']).map((key, index) => {
        return (
            <Member
                key={index}
                id={key}
                img={"team/" + key + ".png"}
                name={props.t('homepage:team.members.' + key + '.name')}
                position={props.t('homepage:team.members.' + key + '.position')}
                email={props.t('homepage:team.members.' + key + '.email')}
            />
        )
    })

    return (
        <section className='Team section-padding'>
            <Container className='container-custom'>
                <div className='title-container d-flex justify-content-center '>
                    <p className='m-0 text-uppercase' >{props.t("homepage:team.title1")}</p>
                </div>
                <Row>
                    {members}
                    {/* uncomment if needed */}
                    {props.quote ?
                        <Col md={8} lg={{ span: 8, offset: 1 }} className='d-none d-md-flex flex-column align-items-end justify-content-center'>
                            <div className='quote d-flex flex-column align-items-end justify-content-center'>
                                <i><h3 className='h2 text-end'>“{props.t("homepage:team.quote_text")}”</h3></i>
                                <i><p className='m-0'>{props.t("homepage:team.quote_author")}</p></i>
                            </div>
                        </Col>
                        : <></>}
                </Row>
            </Container>
        </section>
    )
}

export default withTranslation()(Team);