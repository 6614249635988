import * as actionsTypes from "./actionsTypes";

export const fetchJobs = () => {
    return {
        type: actionsTypes.FETCH_JOBS,
    }
};

export const failFetchJobs = () => {
    return {
        type: actionsTypes.FETCH_JOBS_FAIL
    }
};
export const successFetchJobs = (jobs) => {
    return {
        type: actionsTypes.FETCH_JOBS_SUCCESS,
        jobs: jobs
    }
};

export const setJobStructuredData = (data) => {
    return {
        type: actionsTypes.SET_JOB_STRUCTURED_DATA,
        structured_data: data
    }
}