import { React, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import banner from "../../assets/images/products/banner/banner.png";

const ProductBanner = props => {
    let bannerIcon = props.page && !props.noLogo && !props.service ? require('../../assets/images/logos/logo-' + props.page + '.svg') : !props.noLogo ? require('../../assets/icons/products/' + props.page + '/banner_' + props.page + '.svg') : null;

    useEffect(() => {
        if (props.service === true) {
            props.changeLanguageColor("white")
        } else {
            props.changeLanguageColor("black")
        }
    }, [props.service])

    if (props.noLogo) {
        return (
            <section className='ProductBanner' style={{ backgroundImage: "url(" + banner + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center right 0" }} >
                <div className='content '>
                    <div className='banner-title'>
                        <h1 className='text-uppercase mb-0'><Trans t={props.t} i18nKey={props.t(props.page + ':banner.main_title')} /></h1>
                    </div>
                    <div className='banner-text'>
                        <h1 ><Trans t={props.t} components={{ uppercase: <span className='text-uppercase'></span> }} i18nKey={props.t(props.page + ':banner.title')} /></h1>
                        <p className='m-0'>
                            <Trans t={props.t} i18nKey={props.t(props.page + ':banner.text')}
                                components={{
                                    LinkItSupport: <Link to={props.t('routes:it_support.path')}></Link>,
                                    LinkWebApplication: <Link to={props.t('routes:web.path')}></Link>,
                                    bold: <b></b>
                                }}
                            />
                        </p>
                        {props.i18n.exists(props.page + ':banner.btn') ? <a className='btn' target='_blank' rel="noreferrer" href={props.t("routes:contacts.path")}>{props.t(props.page + ':banner.btn')}</a> : <></>}
                        <div>
                            <div>
                                <section>
                                    <div className="background-div" >
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </section >
        )
    } else {
        return (
            <section className='ProductBanner' style={{ backgroundImage: "url(" + banner + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center right 0" }} >
                <div className='content '>
                    <div className='banner-title'>
                        <div className='d-flex align-items-center'>
                            {bannerIcon ? <Image fluid src={bannerIcon} /> : <></>}
                        </div>
                    </div>
                    <div className='banner-text'>
                        <h1 ><Trans t={props.t} i18nKey={props.t(props.page + ':banner.title')} /></h1>
                        <p className='m-0'>
                            <Trans t={props.t} i18nKey={props.t(props.page + ':banner.text')}
                                components={{
                                    LinkItSupport: <Link to={props.t('routes:it_support.path')}></Link>,
                                    LinkWebApplication: <Link to={props.t('routes:web.path')}></Link>,
                                    bold: <b></b>
                                }}
                            />
                        </p>
                        {props.i18n.exists(props.page + ':banner.btn') ? <a className='btn' target='_blank' rel="noreferrer" href={props.t("routes:contacts.path")}>{props.t(props.page + ':banner.btn')}</a> : <></>}
                        <div>
                            <div>
                                <section>
                                    <div className="background-div" >
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </section >
        )
    }

}

export default ProductBanner;