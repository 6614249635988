import { React, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Droplink = props => {

    useLayoutEffect(() => {
        let menuContainer = document.getElementById("menu-container");
        let menuCriative = document.getElementById("menu-creative");

        if (props.menuHeight > menuContainer.clientHeight) {
            menuCriative.classList.add("overflow")
        } else {
            menuCriative.classList.remove("overflow")
        }
    })

    /* const [parentActive, setParentActive] = useState()

    useEffect(() => {
        let itens = document.getElementsByClassName('menu-link')
        Array.from(itens).map((item) => {
            let parentId = item.id.split('-')[0]
            if (item.classList.contains("active") && parentId.length > 0) {
                setParentActive(parentId)
                document.getElementById(parentId).classList.add("active")
            } else if (parentId !== parentActive && parentId.length > 0) {
                document.getElementById(parentId).classList.remove("active")
            }
        })
    }, []) */

    return (
        <div>
            <p id={props.name} className="m-0 nav-link" onClick={() => {
                if (props.isOpen === props.name) {
                    props.setSubMenuToggle(null)
                } else {
                    props.setSubMenuToggle(props.name)
                }
            }}>{props.tabName}</p>
            <ul id={props.name} className={props.isOpen === props.name ? "menu-drop open" : "menu-drop"}>
                <li className="nav-link">
                    {Object.keys(props.i18n.getResource(props.i18n.language, 'partials')['menu'][props.name]).map((item, index) => {
                        return (
                            <NavLink id={props.name + '-' + index} key={index} onClick={() => { props.setToggleMenu(false); props.setSubMenuToggle(null) }} className="menu-link nav-link menu-item" to={item === "nearshoring" ? "https://www.nearshorit.com/" : props.t('routes:' + item + '.path')} target={item === "nearshoring" ? '_blank' : "_self"}>
                                {props.t('partials:menu.' + props.name + '.' + item)}
                            </NavLink>
                        );
                    })}
                </li>
            </ul>
        </div>
    )
}

export default Droplink
