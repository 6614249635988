import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Image } from 'react-bootstrap';

const Partner = props => {

    let icon = props.icon ? require('../../../assets/icons/homepage/' + props.icon) : "";

    return (
        <Col md={6} className='parther-card'>
            <div className='content'>
                <div>
                    <Image src={icon} alt={props.t("alt:homepage.partners." + props.id)} />
                </div>
                <div>
                    <h3 className='text-uppercase'>{props.title}</h3>
                    <p>{props.text}</p>
                </div>
            </div>
        </Col>
    )

}

export default withTranslation()(Partner);