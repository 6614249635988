import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TextGroup from '../../components/TextGroup/TextGroup';
import CTA from '../../components/CTA/CTA';
class Gdpr extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="gdpr" />
        <ServiceBanner {...this.props} page="gdpr" />
        <ServiceSection noPadBottom noPadTitle {...this.props} page="gdpr" section="infos">
          <TextGroup noPadBottom {...this.props} page="gdpr" section="infos" />
        </ServiceSection>
        <ServiceSection noPadBottom noPadTitle {...this.props} page="gdpr" section="compliance">
          <TextGroup noPadBottom {...this.props} page="gdpr" section="compliance" />
        </ServiceSection>
        <ServiceSection noPadBottom {...this.props} page="gdpr" section="methodology">
          <TextGroup  {...this.props} page="gdpr" section="methodology" />
        </ServiceSection>
        <ServiceSection noTitle {...this.props} page="outsourcing">
          <CTA noBG {...this.props} />
        </ServiceSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(Gdpr);