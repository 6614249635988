import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TextGroup from '../../components/TextGroup/TextGroup';
import Auxiliary from '../../hoc/Auxiliary';
import CTA from '../../components/CTA/CTA';

class Rgpc extends Component {
    render() {
        return (
            <Auxiliary>
                <Head {...this.props} id="rgpc" />
                <ServiceBanner {...this.props} page="rgpc" />
                <ServiceSection noPadBottom noPadTitle {...this.props} page="rgpc" section="infos">
                    <TextGroup noPadBottom {...this.props} page="rgpc" section="infos" />
                </ServiceSection>
                <ServiceSection noPadBottom  {...this.props} page="rgpc" section="principles">
                    <TextGroup {...this.props} page="rgpc" section="principles" />
                </ServiceSection>
                <ServiceSection noPadBottom  {...this.props} page="rgpc" section="responsabilities">
                    <TextGroup {...this.props} page="rgpc" section="responsabilities" />
                </ServiceSection>
                <ServiceSection noPadBottom  {...this.props} page="rgpc" section="implementation">
                    <TextGroup {...this.props} page="rgpc" section="implementation" />
                </ServiceSection>
                <ServiceSection noPadTitle noPadBottom {...this.props} page="rgpc" section="mechanism">
                    <TextGroup {...this.props} page="rgpc" section="mechanism" />
                </ServiceSection>
                <ServiceSection noTitle {...this.props} page="outsourcing">
                    <CTA noBG {...this.props} />
                </ServiceSection>
            </Auxiliary>
        )
    }
}

export default withTranslation()(Rgpc);