import * as actions from '../actions/index';
import { instance_API_AWS } from '../../axios';
import { put } from 'redux-saga/effects';

export function* trySubmitForm(action) {
    try {
        const data = yield {
            "channel": "Match Profiler",
            "toAddress": "joao.branco@m-profiler.com",
            [action.typeForm]: [action.info][0]
        };
        yield instance_API_AWS.post('/send-email', data);

        yield put(actions.sucessSubmitForm());
    } catch (error) {
        yield put(actions.failSubmitForm());
    }
}