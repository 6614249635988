import { React } from 'react';
import { Col, Row } from 'react-bootstrap';

const CareerInfo = props => {
    return (
        <section className={props.noPadBottom ? 'CareerInfo section-padding noPadBottom' : 'CareerInfo section-padding'}>
            <div className='container'>
                <Row className='title-container justify-content-center'>
                    <Col sm={props.section === "recruitment" ? 10 : 8} md={props.section === "recruitment" ? 8 : 6} lg={props.section === "recruitment" ? 6 : 5} xl={props.section === "recruitment" ? 7 : 5}>
                        <h2 className='m-0 text-uppercase'>{props.t("jobs:" + props.section + ".title")}</h2>
                    </Col>
                </Row>
                <div>
                    {props.children}
                </div>
            </div>
        </section>
    )
}

export default CareerInfo;