import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import ProductBanner from '../../components/ProductBanner/ProductBanner';
import ProductSection from '../../components/ProductSection/ProductSection';
import IconCards from '../../components/IconCards/IconCards';
class Smeap extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="smeap" />
        <ProductBanner noLogo {...this.props} page="smeap" />
        <ProductSection noPadTitle noPadBottom {...this.props} page="smeap" section="help">
          <TextGroup noPadBottom {...this.props} page="smeap" section="help" />
        </ProductSection>
        <ProductSection noPadTitle noPadBottom {...this.props} page="smeap" section="disposition">
          <TextGroup noPadBottom {...this.props} page="smeap" section="disposition" />
        </ProductSection>
        <ProductSection noPadTitle noPadBottom {...this.props} page="smeap" section="key">
          <TextGroup noPadBottom {...this.props} page="smeap" section="key" />
        </ProductSection>
        <ProductSection noPadTitle noPadBottom {...this.props} page="smeap" section="technologies">
          <TextGroup {...this.props} page="smeap" section="technologies" />
        </ProductSection>
        <ProductSection noPadTitle noPadTop {...this.props} page="smeap" section="info">
          <IconCards {...this.props} products page="smeap" section="info" />
          <TextGroup noPadBottom {...this.props} page="smeap" section="demo" />
        </ProductSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(Smeap);