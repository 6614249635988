import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import TextGroup from '../../components/TextGroup/TextGroup';
import CTA from '../../components/CTA/CTA';
import ProductBanner from '../../components/ProductBanner/ProductBanner';
import ProductSection from '../../components/ProductSection/ProductSection';
class Consilium extends Component {
  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="consilium" />
        <ProductBanner  {...this.props} page="consilium" />
        <ProductSection noPadTitle noPadBottom {...this.props} page="consilium" section="reunion">
          <TextGroup {...this.props} page="consilium" section="reunion" />
        </ProductSection>
        <ProductSection noPadTitle noPadBottom {...this.props} page="consilium" section="benefits">
          <TextGroup {...this.props} page="consilium" section="benefits" />
        </ProductSection>
        <ProductSection noPadBottom {...this.props} page="consilium" section="functionalities">
          <TextGroup {...this.props} page="consilium" section="functionalities" />
        </ProductSection>
        <ProductSection noTitle noPadTitle noImg {...this.props} page="consilium">
          <CTA noBG {...this.props} />
        </ProductSection>
        {/* <CTA bgYellow {...this.props} /> */}
      </Auxiliary>
    )
  }
}

export default withTranslation()(Consilium);