import { React } from 'react';
import { withTranslation } from 'react-i18next';

import { Row } from 'react-bootstrap';

import ProductCard from './ProductCard/ProductCard';

const ProductCards = props => {
    let ProductsItems = Object.keys(props.i18n.getResource(props.i18n.language, 'homepage')[props.content]['cards']).map((key, index) => {
        return (
            <ProductCard
                key={index}
                title={props.t('homepage:' + props.content + '.cards.' + key + '.title')}
                text={props.t('homepage:' + props.content + '.cards.' + key + '.text')}
                videoID={props.t('homepage:' + props.content + '.cards.' + key + '.video_id')}
                btn={props.t('homepage:' + props.content + '.btn')}
                btnLink={key}
            />
        )
    })

    return (
        <div className='ProductCards p-lg-0'>
            <Row className='gx-0 justify-content-center'>
                {ProductsItems}
            </Row>
        </div>
    )
}

export default withTranslation()(ProductCards);