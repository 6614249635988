import { React } from 'react';
import { Trans } from 'react-i18next';

const OfferBanner = props => {
    return (
        <section className='careerBanner' style={{ backgroundColor: "#FFFFFF" }}>
            <div className='content'>
                <div className='content-container col-12 col-md-10 col-xl-11'>
                    <div className='floating-block'>
                        <div className='floating-block-content'>
                            <h1 className='text-uppercase'><Trans t={props.t} i18nKey={props.t('job:banner.title')} /></h1>
                            <h2><Trans t={props.t} i18nKey={props.t('job:banner.sub-title')} /></h2>
                            <p className='m-0'><Trans t={props.t} i18nKey={props.t('job:banner.text')} /></p>
                        </div>
                        <div>
                            <div>
                                <section>
                                    <div className="background-div" >
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default OfferBanner;