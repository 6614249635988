import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { watchJobs } from './store/sagas/index';
import { watchForm } from './store/sagas/index';
// react-snap
import { hydrate } from "react-dom";

//routes
import { BrowserRouter } from 'react-router-dom';

//REDUCERS
import jobsReducer from './store/reducers/jobs';
import cookiesReducer from './store/reducers/cookies';
import formsReducer from './store/reducers/forms';

//sagas
const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  jobs: jobsReducer,
  cookies: cookiesReducer,
  forms: formsReducer,
});
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk, sagaMiddleware)
));

sagaMiddleware.run(watchJobs);
sagaMiddleware.run(watchForm);

const root = ReactDOM.createRoot(document.getElementById('root'));

if (document.getElementById('root').hasChildNodes()) {
  hydrate(
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </React.StrictMode >
    )
  );
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode >
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
