import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';



export function* tryFetchJobsSaga(action) {
    try {
        const response = yield axios.get('https://mlogapi.m-profiler.com/RecruitmentRequest/GetRecruitmentRequestWebsiteSharingJobs?Page=1&PageSize=2000', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.status === 200) {
            yield put(actions.failFetchJobs());
        }
        const jobs = response.data;
        yield put(actions.successFetchJobs(jobs));
    } catch (error) {
        yield put(actions.failFetchJobs());
    }
}