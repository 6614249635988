import React, { useEffect } from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import { withTranslation } from 'react-i18next';
import OfferBanner from '../../../components/OfferBanner/OfferBanner';
import Head from '../../../components/Partials/Head/Head';
import OfferDescription from '../../../components/OfferDescription/OfferDescription';

const JobOffer = (props) => {
    useEffect(() => {
        props.changeLanguageColor("black")
    }, [])

    return (
        <Auxiliary>
            <Head {...props} id="offer" />
            <OfferBanner {...props} />
            <OfferDescription {...props} />
        </Auxiliary>
    )
}

export default withTranslation()(JobOffer)