import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import ServiceBanner from '../../components/ServiceBanner/ServiceBanner';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TextGroup from '../../components/TextGroup/TextGroup';
import CTA from '../../components/CTA/CTA';
class CompanyCertification extends Component {

  render() {
    return (
      <Auxiliary>
        <Head {...this.props} id="companyCertification" />
        <ServiceBanner {...this.props} page="companyCertification" />
        <ServiceSection noPadTitle noPadBottom  {...this.props} page="companyCertification" section="benefits">
          <TextGroup {...this.props} page="companyCertification" section="benefits" />
        </ServiceSection>
        <ServiceSection noPadBottom noImg noPadTop {...this.props} page="companyCertification" section="certifications">
          <TextGroup {...this.props} page="companyCertification" section="certifications" />
        </ServiceSection>
        <ServiceSection noPadBottom noPadTitle {...this.props} page="companyCertification" section="methodology">
          <TextGroup {...this.props} page="companyCertification" section="methodology" />
        </ServiceSection>
        <ServiceSection noTitle {...this.props} page="companyCertification">
          <CTA noBG {...this.props} />
        </ServiceSection>
      </Auxiliary>
    )
  }
}

export default withTranslation()(CompanyCertification);