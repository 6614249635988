import { React } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { Col, Image } from 'react-bootstrap';

const IconCard = props => {
    let icon = props.products ? require('../../../assets/icons/products/' + props.icon + '.svg') : require('../../../assets/icons/' + props.icon + '.svg');
    return (
        <Col xs={9} sm={6} md={5} lg={3} className='card-container d-block'>
            <div className='text-content'>
                <div className='content'>
                    <div className="semi-bdr-right"></div>
                    <h4><Trans t={props.t} i18nKey={props.title} /></h4>
                    <div className="bottom-bar"></div>
                    <div className='icon-container'>
                        <Image fluid src={icon} />
                    </div>
                    <div className="semi-bdr-bottom"></div>
                </div>
            </div>
        </Col>
    )
}

export default withTranslation()(IconCard);