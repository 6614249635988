import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


// import square from "../../assets/icons/homepage/parthers/square.svg"
// import corner from "../../assets/icons/homepage/parthers/corner.svg"

const Member = props => {
    let img = props.img ? require('../../../assets/images/homepage/' + props.img) : null;
    return (
        <Col sm={6} md={4} lg={3} className='member-card'>
            <div className='member-content'>
                <div className='member-img'>
                    {img ? <Image src={img} alt={props.t("alt:homepage.team." + props.id)} /> : <></>}
                </div>
                <div className='member-info'>
                    <h3>{props.name}</h3>
                    <p>{props.position}</p>
                </div>
                <div className='member-email'>
                    <Link className='email' to={"mailto:" + props.email}>{props.email}</Link>
                </div>
            </div>
        </Col>
    )
}

export default withTranslation()(Member);